import React, {ReactNode} from "react"
import styles from "./App.module.scss"
import {useTranslation} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

interface Props {
    message: string | ReactNode
    children?: ReactNode
}

export const ErrorDisplay: React.FC<Props> = ({message, children}) => {
    const {t} = useTranslation("translations")

    return (
        <div className={styles.errorPage}>
            <FontAwesomeIcon icon="exclamation-circle" size="3x" />

            <h2>{t("error-loading-application")}</h2>
            <p>{message}</p>
            {children}
        </div>
    )
}
