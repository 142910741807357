import React from "react"
import {useTranslation} from "react-i18next"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"
import PageWrapper from "../../../components/PageWrapper"
import OverviewMainContent from "./OverviewMainContent"
import {DropdownProps} from "../../../components/FilterDropdownsSection"
import FilterDropdownsSection from "../../../components/FilterDropdownsSection"

const dropdowns: DropdownProps[] = [
    {
        title: "portfolio",
        options: [
            {name: "Portfolio1", id: "23904"},
            {name: "Portfolio2", id: "98234"},
            {name: "Portfolio3", id: "54345"},
        ],
        icon: "building",
        type: "portfolio",
    },

    {
        title: "type",
        options: [
            {name: "Type1", id: "7665"},
            {name: "Type2", id: "349858"},
            {name: "Type3", id: "45343"},
        ],
        icon: "book",
        type: "type",
    },
]

const Overview = () => {
    const {t} = useTranslation("translations")
    const [overviewFilterOptions, setOverviewFilterOptions] = React.useState({
        portfolio: "",
        type: "",
    })

    const handleChange = (event: SelectChangeEvent) => {
        setOverviewFilterOptions({...overviewFilterOptions, [event.target.name]: event.target.value})
    }

    return (
        <PageWrapper headerTitle={t("overview")} icon={["far", "chart-network"] as IconProp}>
            <FilterDropdownsSection handleChange={handleChange} filterValues={overviewFilterOptions} dropdowns={dropdowns} />
            <OverviewMainContent />
        </PageWrapper>
    )
}

export default Overview
