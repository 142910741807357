import React from "react"
import styles from "../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import {formatAmount} from "../../../../utils/numbers"
import Grid from "@mui/material/Grid"
import GridItemTextCell from "./EditComponents/GridItemTextCell"
import GridItemDateCell from "./EditComponents/GridItemDateCell"
import {StatusReportData} from "../statusReportData"
import {gql, useMutation} from "@apollo/client"
import {UpdateReportOverview, UpdateReportOverviewVariables} from "./types/UpdateReportOverview"
import {useUser} from "../../../../auth/UserProvider"
import {formatStringForTranslation} from "../../../../utils/strings"

const updateReportOverview = gql`
    mutation UpdateReportOverview($report_id: uuid!, $report_title: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {report_title: $report_title}) {
            report_title
        }
    }
`

interface Props {
    report: StatusReportData
    queryRefetch: any
}

export interface OverviewEditTypes {
    report_title: string | null
}

const ReportOverview: React.FC<Props> = ({report, queryRefetch}) => {
    const {t} = useTranslation("translations")
    const user = useUser()

    const [editableOverviewValues, setEditableOverviewValues] = React.useState<OverviewEditTypes>({
        report_title: report.overview.report_title,
    })

    const [update_report_overview] = useMutation<UpdateReportOverview, UpdateReportOverviewVariables>(updateReportOverview)

    const handleUpdateOverview = async () => {
        await update_report_overview({
            variables: {
                report_id: report.id,
                report_title: editableOverviewValues.report_title,
            },
        })
        queryRefetch()
    }

    const gridItemPropPack = {
        handleUpdateOverview: handleUpdateOverview,
        report: report,
        editableOverviewValues: editableOverviewValues,
        setEditableOverviewValues: setEditableOverviewValues,
    }

    // NOTE: Iterating single components over reportData, puts it all in wrong order that is needed from mockup
    return (
        <Grid container spacing={2} className={styles.reportTextFlexWrapper}>
            <GridItemTextCell {...gridItemPropPack} name={t("project-name")} value={report.overview.project_name} />
            <GridItemTextCell
                {...gridItemPropPack}
                name={report.type === "program" ? t("program-report-program-manager") : t("project-manager")}
                value={report.overview.manager?.full_name || "-"}
            />
            <GridItemTextCell {...gridItemPropPack} name={t("portfolio")} value={report.overview.portfolio || "-"} />
            <GridItemTextCell {...gridItemPropPack} name={t("state")} value={t(report.overview.state)} />
            <GridItemTextCell
                {...gridItemPropPack}
                name={report.type === "program" ? t("program-report-status-in-portfolio") : t("project-status")}
                value={report.overview.project_status ? t(`status-${formatStringForTranslation(report.overview.project_status)}`) : "-"}
            />
            <GridItemTextCell
                {...gridItemPropPack}
                name={t("phase")}
                value={report.overview.phase ? t(`phase-${formatStringForTranslation(report.overview.phase)}`) : "-"}
            />
            <GridItemTextCell
                {...gridItemPropPack}
                name={t("report-title")}
                value={report.overview.report_title || "-"}
                editable={true}
                fieldType="report_title"
            />
            <GridItemDateCell {...gridItemPropPack} name={t("report-date")} value={report.overview.report_date} />
            <GridItemDateCell {...gridItemPropPack} name={t("release-date")} value={report.overview.release_date} />
            <GridItemDateCell {...gridItemPropPack} name={t("completion-date")} value={report.overview.completion_date} />
            {/* <Grid item xs={12} sm={6} md={4}></Grid> */}
            <GridItemDateCell name={t("start-date")} value={report.overview.actual_start_date} />
            <GridItemDateCell name={t("planned-end-date")} value={report.overview.planned_end_date} />
            <GridItemDateCell name={t("actual-end-date")} value={report.overview.actual_end_date} />
            {user.isAllowedToSeeFullStatusReport(report.id) && (
                <GridItemTextCell
                    {...gridItemPropPack}
                    name={t("planned-costs")}
                    value={`CHF ${formatAmount(report.overview.planned_costs)}`}
                />
            )}
            {user.isAllowedToSeeFullStatusReport(report.id) && (
                <GridItemTextCell
                    {...gridItemPropPack}
                    name={t("actual-costs")}
                    value={`CHF ${formatAmount(report.overview.actual_costs)}`}
                />
            )}
        </Grid>
    )
}

export default ReportOverview
