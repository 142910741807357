import React, {createRef, useState} from "react"
import {OptionsObject, SnackbarKey, SnackbarProvider, useSnackbar} from "notistack"
import {useTranslation} from "react-i18next"
import * as Sentry from "@sentry/react"
import {Map} from "immutable"
import Button from "@mui/material/Button"
import {useAuthIfAvailable} from "../auth/AuthProvider"
import {AuthUser} from "../auth/auth"
import CloseIcon from "@mui/icons-material/Close"

export const FeedbackProvider: React.FC = ({children}) => {
    const ref = createRef<SnackbarProvider>()
    const dismiss = (key: SnackbarKey) => () => ref.current && ref.current.closeSnackbar(key)
    return (
        <SnackbarProvider
            ref={ref}
            maxSnack={3}
            anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
            }}
            action={(key) => (
                <Button onClick={dismiss(key)}>
                    <CloseIcon />
                </Button>
            )}
        >
            {children}
        </SnackbarProvider>
    )
}

const options: OptionsObject = {
    autoHideDuration: 2000,
    preventDuplicate: true,
}

export function useFeedback(): Feedback {
    const snackbar = useSnackbar()
    const {t, i18n} = useTranslation("feedback")
    const user = useAuthIfAvailable()?.current()
    const [activeProblems, setActiveProblems] = useState<Map<string, string>>(Map<string, string>())

    function reportError(eventId: string, overrideUser?: AuthUser) {
        const u = overrideUser || user
        Sentry.showReportDialog({
            eventId,
            user: {
                email: u?.email,
                name: u?.displayName,
            },
            lang: i18n.language,
        })
    }

    const errorAction = (eventId: string, overrideUser?: AuthUser) => (key: string) =>
        (
            <>
                <Button onClick={() => reportError(eventId, overrideUser)}>{t("report-error")}</Button>
                <Button onClick={() => snackbar.closeSnackbar(key)}>
                    <CloseIcon />
                </Button>
            </>
        )

    return {
        showError(key: string, text: string, error?: Error, opts?) {
            const message = text + (opts?.details ? `: ${opts.details}` : "")
            if (error) {
                console.error(message, error)
                const eventId = Sentry.captureException(error, {
                    extra: {
                        message: text,
                        details: opts?.details,
                    },
                    tags: {
                        displayedBy: "feedback",
                    },
                })
                snackbar.enqueueSnackbar(text, {
                    ...options,
                    key,
                    variant: "error",
                    autoHideDuration: opts?.fatal ? null : 7000,
                    action: errorAction(eventId, opts?.user),
                })
            } else {
                //dont report error, just show
                snackbar.enqueueSnackbar(text, {
                    ...options,
                    key,
                    variant: "error",
                })
            }
        },

        showProblem(key: string, text: string, error?: Error) {
            if (activeProblems.get(key) === text) return
            setActiveProblems(activeProblems.set(key, text))
            console.warn(text, error)
            snackbar.enqueueSnackbar(text, {
                ...options,
                key,
                action: <></>,
                persist: true,
                variant: "warning",
            })
        },

        showSuccess(key: string, text: string) {
            snackbar.enqueueSnackbar(text, {
                ...options,
                key,
                variant: "success",
            })
        },

        showProgress(key: string, text: string) {
            snackbar.enqueueSnackbar(text, {
                ...options,
                key,
                autoHideDuration: null,
                variant: "info",
            })
        },

        clear(key: string) {
            setActiveProblems(activeProblems.remove(key))
            snackbar.closeSnackbar(key)
        },
    }
}

interface Feedback {
    /** Shows an error message. Only actions triggered by the user shall result in an error message. */
    showError(key: string, text: string, error?: Error, options?: {fatal?: boolean; details?: string; user?: AuthUser}): void

    /** show a problem message. A problem occurs in the background (such a connectivity loss) and
     * is not directly related to user input (use showError for that). */
    showProblem(key: string, text: string, error?: Error): void

    showSuccess(key: string, text: string): void

    showProgress(key: string, text: string): void

    clear(key: string): void
}
