import React from "react"
import {KeycloakConfig} from "keycloak-js"

export interface Configuration {
    hostname: string
    keycloak: KeycloakConfig
    hasuraUrl: string
    hasuraAdminSecret?: string
}

export const ConfigurationContext = React.createContext<Configuration | undefined>(undefined)

export function useConfig(): Configuration {
    const context = React.useContext(ConfigurationContext)
    if (!context)
        throw new Error(
            "No ConfigurationContext instance can be found. Please ensure you used 'ConfigurationContextFetch' higher up in your tree"
        )
    return context
}

const hasuraUrl = (hasuraUrl?: string): string => {
    if (hasuraUrl) return hasuraUrl
    return `/v1/graphql`
}

export const ConfigurationContextFetch: React.FC = ({children}) => {
    const base_config = (window as any).__config__

    const config: Configuration = {
        hostname: window.location.hostname.toLowerCase(),
        keycloak: {
            url: base_config.keycloak_url,
            realm: base_config.keycloak_realm,
            clientId: base_config.keycloak_client_id,
        },
        hasuraUrl: hasuraUrl(base_config.hasuraUrl),
        hasuraAdminSecret: base_config.hasuraAdminSecret
    }
    return <ConfigurationContext.Provider value={config}>{children}</ConfigurationContext.Provider>
}
