import React from "react"
import styles from "./StatusReport.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {useTranslation} from "react-i18next"
import {gql, useMutation, useQuery} from "@apollo/client"
import {Link} from "react-router-dom"
import PageWrapper from "../../../components/PageWrapper"
import {useReactToPrint} from "react-to-print"
import {ChangeReportState, ChangeReportStateVariables} from "./types/ChangeReportState"
import {StatusReportState} from "../../../types/graphql-global-types"
import {useFeedback} from "../../../components/Feedback"
import {useNavigate, useParams} from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import {StatusReport} from "./StatusReport"
import {GetStatusReportForAction, GetStatusReportForActionVariables} from "./types/GetStatusReportForAction"
import StatusReportActions, {StatusReportForActionFragment} from "./StatusReportActions"
import StatusReportFilters from "./StatusReportFilters"

const getStatusReportForAction = gql`
    query GetStatusReportForAction($id: uuid!) {
        status_report: status_report_status_report_by_pk(id: $id) {
            ...StatusReportForActionFragment
            id
            state
        }
    }
    ${StatusReportForActionFragment}
`

const changeReportState = gql`
    mutation ChangeReportState($new_state: StatusReportState!, $status_report_id: String!) {
        change_status_report_state(new_state: $new_state, status_report_id: $status_report_id) {
            new_state
            status_report_id
        }
    }
`

export interface SelectedReport {
    reportId: string
}

const ProjectStatusReport = () => {
    const {t} = useTranslation("translations")
    const feedback = useFeedback()
    const navigate = useNavigate()
    const {reportId} = useParams()

    const printStatusReportRef = React.useRef<HTMLDivElement | null>(null)

    const [selectedReport, setSelectedReport] = React.useState<SelectedReport>({
        reportId: reportId ?? "",
    })
    const [reportState, setReportState] = React.useState<string>("")

    React.useEffect(() => {
        if (!selectedReport.reportId) {
            navigate(`/project-management/project-status-report/`)
            return
        }
        navigate(`/project-management/project-status-report/${selectedReport.reportId}`)
    }, [selectedReport, reportState, navigate])

    const handlePrint = useReactToPrint({
        content: () => printStatusReportRef.current,
    })

    const [change_report_state] = useMutation<ChangeReportState, ChangeReportStateVariables>(changeReportState)

    const {data: statusReport, error: statusReportError} = useQuery<GetStatusReportForAction, GetStatusReportForActionVariables>(
        getStatusReportForAction,
        {
            variables: {id: selectedReport.reportId},
            skip: selectedReport.reportId === "",
            onCompleted: (data) => {
                setReportState(data.status_report?.state ?? "")
            },
        }
    )
    if (statusReportError) throw Error(statusReportError?.message)

    const handleReportStateChange = async (newState: StatusReportState) => {
        try {
            await change_report_state({
                variables: {
                    new_state: newState,
                    status_report_id: selectedReport.reportId,
                },
                refetchQueries: ["GetReport", "GetStatusReportForAction", "GetAllReports", "GetReportPermissions"],
                awaitRefetchQueries: true,
            })

            setReportState(newState)
            feedback.showSuccess("report-state-change-success", t("report-state-change-success"))
        } catch (err: any) {
            feedback.showError("report-state-change-error", t("report-state-change-error"), err)
        }
    }

    const shouldShowReport = () => {
        return selectedReport.reportId !== ""
    }

    const getOverviewURL = () => {
        if (statusReport?.status_report?.project && statusReport?.status_report?.project.overview_url)
            return statusReport?.status_report?.project.overview_url
        else if (statusReport?.status_report?.program && statusReport.status_report.program.overview_url)
            return statusReport.status_report.program.overview_url
        else return ""
    }

    return (
        <PageWrapper headerTitle={t("status-reports")} icon={["far", "file-chart-pie"] as IconProp}>
            <div className={styles.dropdownSection}>
                <div className={styles.actionBar}>
                    <StatusReportFilters selectedReport={selectedReport} setSelectedReport={setSelectedReport} />
                    <StatusReportActions statusReport={statusReport} handleReportStateChange={handleReportStateChange} />
                </div>
                <div>
                    {shouldShowReport() && (
                        <IconButton onClick={handlePrint}>
                            <FontAwesomeIcon icon={["fas", "print"] as IconProp} size="xs" />
                        </IconButton>
                    )}
                    {shouldShowReport() && getOverviewURL() && (
                        <IconButton href={getOverviewURL()} target="_blank" style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon={["fas", "external-link-alt"] as IconProp} size="xs" />
                        </IconButton>
                    )}
                </div>
            </div>

            <div className={shouldShowReport() ? styles.reportArea : styles.emptyReportArea}>
                {selectedReport.reportId && shouldShowReport() && (
                    <StatusReport selectedReportId={selectedReport.reportId} ref={printStatusReportRef} />
                )}

                {!shouldShowReport() && (
                    <div>
                        <FontAwesomeIcon icon={["far", "bullseye-pointer"]} size="3x" />
                        <p>{t("no-report-displayed-text")}</p>
                    </div>
                )}
            </div>
        </PageWrapper>
    )
}

export default ProjectStatusReport
