import React from "react"
//style imports
import styles from "./PageHeader.module.scss"
import sharedStyles from "../App.module.scss"
//icon imports
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconName, IconProp} from "@fortawesome/fontawesome-svg-core"

interface Props {
    headerTitle: string
    icon: IconName | IconProp
}

const PageHeader: React.FC<Props> = ({headerTitle, icon}) => {

    return (
        <div className={styles.mainContentWrapper}>
            <div className={sharedStyles.iconLinkWrapper}>
                <div className={styles.mainContentHeaderIcon}>
                    <FontAwesomeIcon icon={icon}/>
                </div>
                <h2>{headerTitle}</h2>
            </div>
        </div>
    )
}

export default PageHeader
