import React from "react"
import styles from "./PlanBudgetActualComparison.module.scss"
import PlanBudgetActualComparison from "./Charts/PlanBudgetActualComparison"
import PlanBudgetActualComparisonAll from "./Charts/PlanBudgetActualComparisonAll"
import {t} from "i18next"

const PSDMainContent = () => {
    return (
        <div className={styles.mainContentWrapper}>
            <div className={styles.graphContainer}>
                <h5>{t("plan-budget-actual-comparison-all-chart-title")}</h5>
                <div style={{height: "300px"}}>
                    <PlanBudgetActualComparisonAll />
                </div>
            </div>
            <div className={styles.graphContainer}>
                <h5>{t("plan-budget-actual-comparison-chart-title")}</h5>
                <div style={{height: "300px"}}>
                    <PlanBudgetActualComparison />
                </div>
            </div>
        </div>
    )
}

export default PSDMainContent
