import React from "react"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import {formatAmount} from "../../../../utils/numbers"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import {useCollapseStyles} from "./collapseStyle"
import {PortfolioBudgetData} from "./portfolioBudgetData"

interface Props {
    title: string
    data: PortfolioBudgetData
    amount: (data: PortfolioBudgetData) => number
    getTotal: (data: PortfolioBudgetData) => number | null | undefined
    getTotalPerRow: (data: PortfolioBudgetData) => number | null | undefined
}

const BudgetTableRow: React.FC<Props> = ({title, data, amount, getTotal, getTotalPerRow}) => {
    const classes = useCollapseStyles()

    const [open, setOpen] = React.useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <TableRow
                sx={{
                    "&:last-child td, &:last-child th": {border: 0},
                    transition: "all 300ms",
                    "&.MuiTableRow-root:hover": {
                        backgroundColor: "#f8f8f8",
                        cursor: "pointer",
                    },
                }}
                onClick={handleClick}
            >
                <TableCell sx={{width: "30px"}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{width: 240, maxWidth: 240}}>
                    {title}
                </TableCell>
                <TableCell align="right">{formatAmount(getTotal(data))}</TableCell>
                {data.years.map((year, index) => {
                    return (
                        <TableCell align="right" key={index} sx={{width: 155, maxWidth: 155}}>
                            {formatAmount(amount(data.inYear(year)))}
                        </TableCell>
                    )
                })}
            </TableRow>
            {data.overviews &&
                data.groupByControllingPlan().map((controllingPlan) => {
                    return (
                        <TableRow
                            key={`${title}_${controllingPlan.controllingPlanId}`}
                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                            className={
                                !open ? `${classes.row} ${classes.childRow} ${classes.closeRow}` : `${classes.row} ${classes.childRow} `
                            }
                        >
                            <TableCell className={!open ? `${classes.cell} ${classes.closeCell}` : `${classes.cell}`}>
                                <Collapse in={open}>
                                    <KeyboardArrowDownIcon sx={{marginLeft: "-3px", visibility: "hidden"}} />
                                </Collapse>
                            </TableCell>
                            <TableCell
                                className={!open ? `${classes.cell} ${classes.closeCell}` : `${classes.cell}`}
                                align="left"
                                sx={{width: 240, maxWidth: 240}}
                            >
                                <Collapse in={open}>{controllingPlan.name}</Collapse>
                            </TableCell>
                            <TableCell align="right" className={!open ? `${classes.cell} ${classes.closeCell}` : `${classes.cell}`}>
                                <Collapse in={open}>{formatAmount(getTotalPerRow(controllingPlan.data))}</Collapse>
                            </TableCell>
                            {data.years.map((year) => {
                                return (
                                    <TableCell
                                        key={`${title}_${controllingPlan.controllingPlanId}_${year}`}
                                        className={!open ? `${classes.cell} ${classes.closeCell}` : `${classes.cell}`}
                                        sx={{width: 155, maxWidth: 155}}
                                        align="right"
                                    >
                                        <Collapse in={open}> {formatAmount(amount(controllingPlan.data.inYear(year)))}</Collapse>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
        </>
    )
}

export default BudgetTableRow
