import React from "react"
import {ResponsiveLine} from "@nivo/line"
import useMediaQuery from "@mui/material/useMediaQuery"

const data = [
    {
        id: "Grün",
        color: "hsl(120, 59%, 65%)",
        data: [
            {
                x: "Jan 2020",
                y: 151,
            },
            {
                x: "Feb 2020",
                y: 147,
            },
            {
                x: "Mar 2020",
                y: 153,
            },
            {
                x: "Apr 2020",
                y: 143,
            },
            {
                x: "Jun 2020",
                y: 152,
            },
            {
                x: "Jul 2020",
                y: 150,
            },
            {
                x: "Aug 2020",
                y: 145,
            },
            {
                x: "Sep 2020",
                y: 152,
            },
            {
                x: "Oct 2020",
                y: 146,
            },
            {
                x: "Nov 2020",
                y: 156,
            },
        ],
    },
    {
        id: "Rot",
        color: "hsl(18, 70%, 50%)",
        data: [
            {
                x: "Jan 2020",
                y: 147,
            },
            {
                x: "Feb 2020",
                y: 140,
            },
            {
                x: "Mar 2020",
                y: 142,
            },
            {
                x: "Apr 2020",
                y: 150,
            },
            {
                x: "Jun 2020",
                y: 145,
            },
            {
                x: "Jul 2020",
                y: 150,
            },
            {
                x: "Aug 2020",
                y: 179,
            },
            {
                x: "Sep 2020",
                y: 173,
            },
            {
                x: "Oct 2020",
                y: 185,
            },
            {
                x: "Nov 2020",
                y: 156,
            },
        ],
    },
    {
        id: "Gelb",
        color: "hsl(347, 70%, 50%)",
        data: [
            {
                x: "Jan 2020",
                y: 163,
            },
            {
                x: "Feb 2020",
                y: 168,
            },
            {
                x: "Mar 2020",
                y: 162,
            },
            {
                x: "Apr 2020",
                y: 160,
            },
            {
                x: "Jun 2020",
                y: 168,
            },
            {
                x: "Jul 2020",
                y: 163,
            },
            {
                x: "Aug 2020",
                y: 164,
            },
            {
                x: "Sep 2020",
                y: 164,
            },
            {
                x: "Oct 2020",
                y: 168,
            },
            {
                x: "Nov 2020",
                y: 159,
            },
        ],
    },
]

const Line = () => {
    const isXSScreen = useMediaQuery("(max-width: 600px)")

    return (
        <ResponsiveLine
            data={data}
            margin={isXSScreen ? {top: 20, right: 20, bottom: 20, left: 30} : {top: 30, right: 90, bottom: 30, left: 60}}
            xScale={{
                type: "point",
            }}
            yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
            }}
            curve="cardinal"
            colors={["#4BB543", "#D0342C", "#EED227"]}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Satusbericht Anzahl",
                legendOffset: -40,
                legendPosition: "middle",
            }}
            axisBottom={
                isXSScreen
                    ? {
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: -50,
                          legendPosition: "middle",
                          legendOffset: 32,
                      }
                    : {
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendPosition: "middle",
                          legendOffset: 32,
                      }
            }
            pointSize={10}
            pointBorderWidth={2}
            pointBorderColor="#ffffff"
            enablePointLabel={true}
            pointLabel="y"
            pointLabelYOffset={-12}
            animate={true}
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 115,
                    translateY: -70,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                },
            ]}
        />
    )
}

export default Line
