import React from "react"
import styles from "./PortfolioReport.module.scss"
import {useTranslation} from "react-i18next"
import ByCategoryPieChart from "./Charts/ByCategoryPieChart"
import ByTypePieChart from "./Charts/ByTypePieChart"
import ByPhaseBarchart from "./Charts/ByPhaseBarchart"
import ByKindPieChart from "./Charts/ByKindPieChart"
import PortfolioEvaluationPhasePlanned from "./Charts/PortfolioEvaluationPhasePlanned"
import BudgetTable from "./BudgetTable/BudgetTable"
import TotalProjectsByStatus from "./Charts/TotalProjectsByStatus"
import StatusPieChart from "./Charts/StatusPieChart"
import PortfolioReportProjectListTable from "./PortfolioReportProjectListTable"
import {PortfolioReportData} from "./portfolioReportData"
import {statusTypes, toProjectState} from "./Charts/projectStateData"
import ByPriorityPieChart from "./Charts/ByPriorityPieChart"

interface Props {
    data: PortfolioReportData
}

const ReportPrintVersion: React.FC<Props> = ({data}) => {
    const {t} = useTranslation("translations")

    return (
        <div className={styles.reportPrintVersion}>
            <div className={`${styles.portfolioReportArea}`}>
                {/* Overview Section */}

                <div className={`${styles.gridContainerSection}`} style={{margin: "5px 0px"}}>
                    <div className={styles.topLineOverview}>
                        <div style={{textAlign: "left"}}>
                            <div className={styles.reportHeader}>
                                <h3>{t("portfolio-report-overview-section")} </h3>
                                <div className={styles.lineDecoration}></div>
                            </div>
                        </div>
                        <div className={styles.activeReportsWrapper}>
                            <p>
                                <span className={styles.activeReportText}>{t("active-programs")}:</span>
                                <span className={styles.activeReportNumber}>{data.numberOfPrograms()}</span>
                            </p>
                            <p>
                                <span className={styles.activeReportText}>{t("active-projects")}:</span>
                                <span className={styles.activeReportNumber}>{data.numberOfProjects()}</span>
                            </p>
                        </div>
                    </div>

                    <div>
                        <p className={styles.descriptionText}>{t("overview-description-text")}</p>
                    </div>
                    <div style={{height: "300px", width: "720px"}} className={`${styles.gridItem} ${styles.portEvalBubbleChart}`}>
                        <h5>{t("portfolio-report-portfolio-evaluation")}</h5>
                        <PortfolioEvaluationPhasePlanned data={data} printVersion={true} />
                    </div>

                    <div className={styles.printPhaseBarChartsWrapper}>
                        <div style={{height: "300px", width: "350px"}} className={`${styles.gridItem} ${styles.barchartOne}`}>
                            <h5>{t("portfolio-report-programs-by-phase")}</h5>
                            <ByPhaseBarchart data={data.programs()} programOrProject="program" printVersion={true} />
                        </div>
                        <div style={{height: "300px", width: "350px"}} className={`${styles.gridItem} ${styles.barchartTwo}`}>
                            <h5>{t("portfolio-report-projects-by-phase")}</h5>
                            <ByPhaseBarchart data={data.projects()} programOrProject="project" printVersion={true} />
                        </div>
                    </div>
                </div>

                {/* Portfolio Composition Section */}
                <div className={`${styles.gridContainerSection} ${styles.portfolioCompSection}`} style={{margin: "150px 0px"}}>
                    <div style={{textAlign: "left"}}>
                        <div className={styles.reportHeader}>
                            <h3>{t("portfolio-report-portfolio-composition")}</h3>
                            <div className={styles.lineDecoration}></div>
                        </div>
                    </div>
                    <div>
                        <p className={styles.descriptionText}>{t("portfolio-composition-text")}</p>
                    </div>
                    <div className={styles.portfolioCompPieChartsWrapper}>
                        <div
                            style={{height: "200px", width: "220px", marginRight: "40px"}}
                            className={`${styles.gridItem} ${styles.pieChart} `}
                        >
                            <h5 style={{marginBottom: "0px"}}>{t("portfolio-report-projects-by-type")}</h5>
                            <ByTypePieChart data={data} printVersion={true} />
                        </div>
                        <div
                            style={{height: "200px", width: "220px", marginRight: "40px"}}
                            className={`${styles.gridItem} ${styles.pieChart} `}
                        >
                            <h5>{t("portfolio-report-projects-by-category")}</h5>
                            <ByCategoryPieChart data={data} printVersion={true} />
                        </div>
                        <div
                            style={{height: "200px", width: "220px", marginRight: "40px"}}
                            className={`${styles.gridItem} ${styles.pieChart} `}
                        >
                            <h5>{t("portfolio-report-projects-by-kind")}</h5>
                            <ByKindPieChart data={data} printVersion={true} />
                        </div>
                        <div
                            style={{height: "200px", width: "220px", marginRight: "40px"}}
                            className={`${styles.gridItem} ${styles.pieChart} `}
                        >
                            <h5>{t("portfolio-report-projects-by-kind")}</h5>
                            <ByPriorityPieChart data={data} printVersion={true} />
                        </div>
                    </div>
                </div>

                {/* Budget Section */}
                <div className={`${styles.gridContainerSection}`} style={{marginTop: "-70px"}}>
                    <div style={{textAlign: "left"}}>
                        <div className={styles.reportHeader}>
                            <h3>{t("portfolio-report-budget-section")}</h3>
                            <div className={styles.lineDecoration}></div>
                        </div>
                    </div>
                    <div>
                        <p className={styles.descriptionText}>{t("budget-table-description-text")}</p>
                    </div>
                    <div>
                        <BudgetTable data={data} yearsDisplayed={(o) => o.period === new Date().getFullYear()} />
                    </div>
                </div>

                {/* Project Status Section */}
                <div className={`${styles.gridContainerSection}`} style={{marginTop: "30px"}}>
                    <div style={{textAlign: "left"}}>
                        <div className={styles.reportHeader}>
                            <h3>{t("project-status")}</h3>
                            <div className={styles.lineDecoration}></div>
                        </div>
                    </div>
                    <div>
                        <p className={styles.descriptionText}>{t("project-status-description-text")}</p>
                    </div>
                    <div style={{height: "300px", width: "700px"}} className={`${styles.gridItem}`}>
                        <h5>{t("portfolio-report-project-status-cumulative-bar-chart")}</h5>
                        <TotalProjectsByStatus data={toProjectState(data).withType("total")} printVersion={true} />
                    </div>
                    <div
                        className={styles.statusPieChartsWrapper}
                        style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", marginTop: "50px"}}
                    >
                        {statusTypes.map((type, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{height: "250px", width: "220px", display: "block", marginTop: "80px"}}
                                    className={`${styles.gridItem}`}
                                >
                                    <h5>{t(`portfolio-report-current-status-${type}`)}</h5>
                                    <StatusPieChart type={type} data={toProjectState(data).withType(type)} printVersion={true} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* Project List Table */}
                <div className={`${styles.gridContainerSection} ${styles.printTableHeader}`} style={{margin: "50px 0px"}}>
                    <div className={styles.reportHeader}>
                        <h3>{t("portfolio-report-portfolio-list")}</h3>
                        <div className={styles.lineDecoration}></div>
                    </div>
                    <p className={styles.descriptionText}>{t("project-list-description-text")}</p>
                    <PortfolioReportProjectListTable data={data.projectsAndPrograms()} />
                </div>
            </div>
        </div>
    )
}

export default ReportPrintVersion
