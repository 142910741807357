import React from "react"
//style imports
import styles from "./SharedFiles.module.scss"
//package imports
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import IconButton from "@mui/material/IconButton"
import Checkbox from "@mui/material/Checkbox"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import Tooltip from "@mui/material/Tooltip"

//component imports

interface FileTypes {
    name: string
    type: string
    size: number
}

interface FileChipTypes {
    file: FileTypes
}

function createData(sentFrom: string, project: string, theme: string, notes: string, file: FileTypes[], id: string) {
    return {sentFrom, project, theme, notes, file, id}
}

const rows = [
    createData(
        "Stefan Haller",
        "Projeltplatform BE",
        "Nullam vulputate orci scelerisque, vehicula…",
        "Maecenas sed semper ex, sed fringilla risus?",
        [{name: "Aenean at nisi leo", type: "file-word", size: 1.2}],
        "lsdkflsdkf"
    ),
    createData(
        "Andreas Hillebrand",
        "Neues Fallführungssystem",
        "Vivamus ut nibh vitae dolor semper blandit ",
        "Vivamus ut nibh vitae dolor semper blandit a eu eros. Donec in purus ut lorem habitant morbi augue justo ullamcorper",
        [{name: "Vestibulum risus dolor", type: "file-excel", size: 2.7}],
        "fdghhghjdfg"
    ),
    createData(
        "Andreas Hillebrand",
        "Neues Fallführungssystem",
        "Pellentesque habitant morbi tristique senectus",
        "Fusce euismod elit ac volutpat fringilla",
        [{name: "Donec varius magna et eros vulputate sagittis", type: "file-word", size: 1.2}],
        "werdsdfdsg"
    ),
    createData(
        "Stefan Haller",
        "Projeltplatform BE",
        "Sed sodales diam non eros tristique maximus",
        "In feugiat, nibh et pharetra lobortis, augue justo ullamcorper felis tristique maxi",
        [
            {name: "In feugiat, nibh et phar", type: "file-word", size: 1.2},
            {name: "Cras scelerisque mi et magna", type: "file-excel", size: 4.7},
        ],
        "ghkjfgfhjhj"
    ),
    createData(
        "Andreas Hillebrand",
        "Projeltplatform BE",
        "Suspendisse condimentum, sapien vel bibendum",
        "",
        [{name: "In luctus ex orci", type: "video", size: 14.3}],
        "sdffhjkhdjghj"
    ),
    createData(
        "Stefan Haller",
        "Projeltplatform BE",
        "Etiam eu tincidunt lacus",
        "Maecenas sed semper ex, sed fringilla risus?",
        [
            {name: "Donec sed ante ex", type: "image", size: 1.5},
            {name: "Pellentesque efficitur", type: "file-word", size: 1.1},
        ],
        "sdtydfgfigb"
    ),
]

const SharedFilesTable = () => {
    const {t} = useTranslation("translations")

    const iconColorPicker = (fileType: string) => {
        if (fileType === "file-word") return "#1857B8"
        if (fileType === "file-excel") return "#1A6E44"
        else return "#8E8E8E"
    }

    const FileChip: React.FC<FileChipTypes> = (file) => {
        return (
            <div className={styles.fileChip}>
                <div className={styles.fileChipHeader}>
                    <FontAwesomeIcon icon={file.file.type as IconProp} size="1x" style={{color: iconColorPicker(file.file.type)}} />
                    <Tooltip title={file.file.name} enterDelay={500} enterNextDelay={500}>
                        <p>{file.file.name}</p>
                    </Tooltip>
                </div>
                <span>{file.file.size}</span>
            </div>
        )
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{borderBottom: "2px solid red", width: "20px"}} align="left"></TableCell>
                            <TableCell sx={{borderBottom: "2px solid red"}} align="left">
                                {t("sent-from")}
                            </TableCell>
                            <TableCell sx={{borderBottom: "2px solid red"}} align="left">
                                {t("theme")}
                            </TableCell>
                            <TableCell sx={{borderBottom: "2px solid red"}} align="left">
                                {t("notes-and-data")}
                            </TableCell>
                            <TableCell sx={{borderBottom: "2px solid red"}} align="right">
                                {/* Empty header for icons */}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                <TableCell>
                                    <Checkbox />
                                </TableCell>
                                <TableCell className={styles.firstColumn}>
                                    <p>{row.sentFrom}</p>
                                    <p>{row.project}</p>
                                </TableCell>
                                <TableCell align="left">
                                    <p>{row.theme}</p>
                                </TableCell>
                                <TableCell align="left">
                                    <p className={styles.notesText}>{row.notes}</p>
                                    <div className={styles.fileChipsWrapper}>
                                        {row.file.map((file) => {
                                            return <FileChip file={file} key={file.size} />
                                        })}
                                    </div>
                                </TableCell>
                                <TableCell align="right">
                                    <div className={styles.rowIcons}>
                                        <Tooltip title={t("archive") || ""} enterDelay={500} enterNextDelay={500}>
                                            <IconButton aria-label="archive">
                                                <FontAwesomeIcon icon="archive" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t("delete-shared-file") || ""} enterDelay={500} enterNextDelay={500}>
                                            <IconButton aria-label="trash">
                                                <FontAwesomeIcon icon="trash" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default SharedFilesTable
