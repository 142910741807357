import React from "react"
import styles from "../../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import IconButton from "@mui/material/IconButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import TableCell from "@mui/material/TableCell"
import TextField from "@mui/material/TextField"
import {GetCurrentStatus_status_report_project_state} from "../types/GetCurrentStatus"

interface Props {
    isReportEditable: boolean
    state: GetCurrentStatus_status_report_project_state
    handleStateUpdate: (statusValues: StatusProps) => Promise<void>
}

export interface StatusProps {
    type: string
    comment: string | null
    state: string
    reportId: string
}

const StateEditor: React.FC<Props> = ({isReportEditable, state, handleStateUpdate}) => {
    const {t} = useTranslation("translations")
    const [editToggle, setEditToggle] = React.useState<boolean>(false)
    const [commentEditToggle, setCommentEditToggle] = React.useState<boolean>(false)
    const [displayHelperText, setDisplayHelperText] = React.useState<boolean>(false)
    const [stateValues, setStateValues] = React.useState<StatusProps>({
        type: state.type,
        comment: state.comment ? state.comment : "",
        state: state.state,
        reportId: state.status_report_id,
    })

    React.useEffect(() => {
        if ((stateValues.state === "major-difficulties" || stateValues.state === "minor-difficulties") && editToggle) {
            setCommentEditToggle(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateValues])

    const handleClose = (e: MouseEvent | TouchEvent | React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (stateValues.state !== "in-order" && !stateValues.comment) {
            setDisplayHelperText(true)
        } else if (stateValues.state === "in-order" || (stateValues.state !== "in-order" && stateValues.comment)) {
            setEditToggle(false)
            setCommentEditToggle(false)
            handleStateUpdate(stateValues)
        }
    }

    const stateIconPicker = (state: string) => {
        if (state === "in-order") return <FontAwesomeIcon icon="check-circle" style={{color: "#48c792", fontSize: "0.875rem"}} />
        if (state === "major-difficulties")
            return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337", fontSize: "0.875rem"}} />
        else return <FontAwesomeIcon icon="minus-circle" style={{color: "#dfd139", fontSize: "0.875rem"}} />
    }

    return (
        <>
            {/* //STATE */}
            <TableCell align="right" style={{width: "25%"}}>
                <div className={styles.stateEditWrapper} onDoubleClick={() => setEditToggle(true)}>
                    {editToggle && isReportEditable ? (
                        <ClickAwayListener onClickAway={(e) => handleClose(e)} mouseEvent={"onMouseUp"}>
                            <div>
                                <FormControl size="small">
                                    <Select
                                        autoWidth
                                        id="dropdown-select"
                                        value={stateValues.state}
                                        onChange={(e) => setStateValues({...stateValues, state: e.target.value})}
                                        inputProps={{
                                            sx: {padding: "0px 14px"},
                                        }}
                                        displayEmpty
                                        renderValue={
                                            stateValues.state !== ""
                                                ? () => {
                                                      return <div style={{marginRight: "6px"}}>{stateIconPicker(stateValues.state)}</div>
                                                  }
                                                : undefined
                                        }
                                    >
                                        <MenuItem value={"in-order"}>
                                            {stateIconPicker("in-order")}
                                            <span style={{marginLeft: "10px"}}>{`${t("in-order")}`}</span>
                                        </MenuItem>
                                        <MenuItem value={"minor-difficulties"}>
                                            {stateIconPicker("minor-difficulties")}
                                            <span style={{marginLeft: "10px"}}> {t("minor-difficulties")}</span>
                                        </MenuItem>
                                        <MenuItem value={"major-difficulties"}>
                                            {stateIconPicker("major-difficulties")}
                                            <span style={{marginLeft: "10px"}}>{t("major-difficulties")}</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </ClickAwayListener>
                    ) : (
                        <p>{stateIconPicker(state.state)}</p>
                    )}
                    <div className={styles.stateEditIconWrapper}>
                        {/* <p>{name}:</p> */}
                        {isReportEditable && !editToggle && (
                            <IconButton aria-label="edit" onClick={() => setEditToggle(true)} style={{marginLeft: "10px"}}>
                                <FontAwesomeIcon icon="edit" style={{fontSize: "0.7rem"}} />
                            </IconButton>
                        )}
                    </div>
                </div>
            </TableCell>

            {/* //COMMENT */}
            <TableCell align="left" style={{width: "70%"}}>
                <div className={styles.commentEditWrapper} onDoubleClick={() => setCommentEditToggle(true)}>
                    {commentEditToggle && isReportEditable ? (
                        <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                            <div style={{width: "100%"}}>
                                <TextField
                                    error={displayHelperText ? true : false}
                                    helperText={displayHelperText ? t("comment-must-be-filled-helper-text") : undefined}
                                    multiline
                                    autoFocus
                                    fullWidth
                                    id="value-textfield"
                                    variant="outlined"
                                    value={stateValues.comment}
                                    onChange={(e) => setStateValues({...stateValues, comment: e.target.value})}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") handleClose(e)
                                    }}
                                    inputProps={{
                                        sx: {
                                            fontSize: "0.875rem",
                                        },
                                    }}
                                />
                            </div>
                        </ClickAwayListener>
                    ) : (
                        <div>
                            {state.comment ? <p>{state.comment}</p> : <p className={styles.noCommentText}>{t("no-comment-added")}</p>}
                        </div>
                    )}
                    <div className={styles.stateEditIconWrapper}>
                        {isReportEditable && !commentEditToggle && (
                            <IconButton aria-label="edit" onClick={() => setCommentEditToggle(true)} style={{marginLeft: "10px"}}>
                                <FontAwesomeIcon icon="edit" style={{fontSize: "0.7rem"}} />
                            </IconButton>
                        )}
                    </div>
                </div>
            </TableCell>
        </>
    )
}
export default StateEditor
