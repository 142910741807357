import {differenceInDays, max} from "date-fns"
import {
    GetReport,
    GetReport_releasedOrInReview_report,
    GetReport_releasedOrInReview_report_project_states_status_report_status_report,
    GetReport_releasedOrInReview_report_status_report_attachments,
    GetReport_unreleasedOrRejected_report,
    GetReport_unreleasedOrRejected_report_status_report_attachments,
} from "./types/GetReport"
import {AuthUser} from "../../../auth/auth"

export class StatusReportData {
    constructor(
        public readonly id: string,
        public readonly type: "program" | "project",
        public readonly overview: StatusReportOverview,
        public readonly attachments: StatusReportAttachments,
        public readonly summary: StatusReportSummary,
        public readonly states: StatusReportState[],
        public readonly milestones: StatusReportMilestone[],
        public readonly risks: StatusReportRisk[],
        public readonly problems: StatusReportProblem[],
        public readonly changeRequests: StatusReportChangeRequest[]
    ) {}

    currentState(): StatusReportState {
        return this.states.filter((state) => state.report_id === this.id)[0]
    }

    isEditableBy(user: AuthUser | null): boolean {
        if (user == null) return false

        if (this.overview.state === "unreleased") return this.isManager(user)
        if (this.overview.state === "rejected") return this.isManager(user)
        if (this.overview.state === "in_review") return this.isSponsor(user)
        return false
    }

    isManager(user: AuthUser): boolean {
        return this.overview.manager?.id === user.email || this.overview.deputy_manager_id === user.email
    }

    isSponsor(user: AuthUser): boolean {
        return this.overview.sponsor_id === user.email || this.overview.deputy_sponsor_id === user.email
    }
}

export interface StatusReportOverview {
    project_name: string
    manager: User | null
    deputy_manager_id: string | null
    sponsor_id: string | null
    deputy_sponsor_id: string | null
    project_status: string | null
    portfolio: string
    state: string
    phase: string | null
    planned_start_date: Date | null
    planned_end_date: Date | null
    planned_costs: number | null
    actual_start_date: Date | null
    actual_end_date: Date | null
    actual_costs: number | null
    report_date: Date
    report_title: string | null
    completion_date: Date | null
    release_date: Date | null
}

export interface User {
    id: string
    full_name: string
}

export interface StatusReportSummary {
    description: string | null
    past_successes: string | null
    next_milestones: string | null
}

export interface StatusReportAttachments {
    attachments:
        | GetReport_releasedOrInReview_report_status_report_attachments[]
        | GetReport_unreleasedOrRejected_report_status_report_attachments[]
}

export type StatusReportStateType = "costs" | "resources" | "schedule" | "scope" | "total"
export type StatusReportStateValue = "in-order" | "major-difficulties" | "minor-difficulties"

export interface StatusReportState {
    id: string
    state: StatusReportStateValue
    type: StatusReportStateType
    comment: string | null
    date: Date
    report_id: string
}

export class StatusReportMilestone {
    constructor(
        public readonly id: string,
        public readonly description: string,
        public readonly status: string,
        public readonly planned_date: Date | null,
        public readonly actual_date: Date | null
    ) {}

    variance(): number | undefined {
        if (this.planned_date && this.actual_date) {
            return differenceInDays(this.actual_date, this.planned_date)
        }
    }
}

export type StatusReportRiskStatus = "Open" | "In Progress" | "Eingetreten" | "Nicht eingetreten"

export class StatusReportRisk {
    constructor(
        public readonly id: string,
        public readonly status: StatusReportRiskStatus,
        public readonly description: string,
        public readonly probability_of_occurrence: string | null,
        public readonly damage_extent: string | null,
        public readonly estimated_cost: number | null,
        public readonly assignee: User | null,
        public readonly next_assessment: Date | null,
        public readonly countermeasures: StatusReportRiskCountermeasure[]
    ) {}
}

export class StatusReportRiskCountermeasure {
    constructor(
        public readonly id: string,
        public readonly status: string,
        public readonly description: string,
        public readonly assignee: User | null,
        public readonly due_date: Date | null
    ) {}
}

export interface StatusReportProblem {
    id: string
    name: string
    assignee: User | null
    status: string
    due_date: Date | null
}

export interface StatusReportChangeRequest {
    description: string
    reporter: User | null
    assignee: User | null
    status: string
}

const toDate = (date: string | null): Date | null => {
    if (date) {
        return new Date(date)
    }
    return null
}

export const unreleasedOrRejectedReport = (report: GetReport): GetReport_unreleasedOrRejected_report | undefined => {
    if (report.unreleasedOrRejected_report?.length > 0) {
        return report.unreleasedOrRejected_report[0]
    }
}

const inReviewOrReleasedReport = (report: GetReport): GetReport_releasedOrInReview_report | undefined => {
    if (report.releasedOrInReview_report?.length > 0) {
        return report.releasedOrInReview_report[0]
    }
}

const getReportByState = (report: GetReport): GetReport_unreleasedOrRejected_report | GetReport_releasedOrInReview_report => {
    if (unreleasedOrRejectedReport(report)) {
        return unreleasedOrRejectedReport(report)!
    }
    return inReviewOrReleasedReport(report)!
}

const overview = (report: GetReport): StatusReportOverview => {
    if (inReviewOrReleasedReport(report)) {
        const inReviewOrReleased = inReviewOrReleasedReport(report)!
        const project = inReviewOrReleased.portfolio_project
        const program = inReviewOrReleased.portfolio_program

        return {
            project_name: inReviewOrReleased.project_name,
            manager: inReviewOrReleased.project_manager_user,
            deputy_manager_id: program?.deputy_program_lead || project?.deputy_project_lead || null,
            sponsor_id: program?.sponsor || project?.sponsor || null,
            deputy_sponsor_id: program?.deputy_sponsor || project?.deputy_sponsor || null,
            project_status: inReviewOrReleased.project_status,
            portfolio: inReviewOrReleased.portfolio,
            state: inReviewOrReleased.state,
            phase: inReviewOrReleased.project_phase,
            planned_start_date: toDate(inReviewOrReleased.planned_start_date),
            planned_end_date: toDate(inReviewOrReleased.planned_end_date),
            planned_costs: inReviewOrReleased.planned_costs,
            actual_start_date: toDate(inReviewOrReleased.actual_start_date),
            actual_end_date: toDate(inReviewOrReleased.actual_end_date),
            actual_costs: inReviewOrReleased.actual_costs,
            report_date: toDate(inReviewOrReleased.report_date)!,
            report_title: inReviewOrReleased.report_title,
            completion_date: latestStateChangeTo("in_review", report),
            release_date: latestStateChangeTo("released", report),
        }
    }

    const unreleased = unreleasedOrRejectedReport(report)!
    const project = unreleased.portfolio_project
    const program = unreleased.portfolio_program

    return {
        project_name: (project?.summary || program?.summary)!,
        manager: (project?.project_lead_user || program?.program_lead_user)!,
        deputy_manager_id: project?.deputy_project_lead || program?.deputy_program_lead || null,
        sponsor_id: project?.sponsor || program?.sponsor || null,
        deputy_sponsor_id: project?.deputy_sponsor || program?.deputy_sponsor || null,
        project_status: (project?.status || program?.status)!,
        portfolio: (project?.portfolio?.name || program?.portfolio?.name)!,
        state: unreleased.state,
        phase: project?.phase || program?.phase || null,
        planned_start_date: toDate(project?.planned_start_date || program?.planned_start_date || null),
        planned_end_date: toDate(project?.planned_end_date || program?.planned_end_date || null),
        planned_costs: report.costs.total_planned,
        actual_start_date: toDate(project?.actual_start_date || program?.actual_start_date || null),
        actual_end_date: toDate(project?.actual_end_date || program?.actual_end_date || null),
        actual_costs: report.costs.total_actual,
        report_date: new Date(unreleased.report_date || ""),
        report_title: unreleased.report_title,
        completion_date: new Date(),
        release_date: null,
    }
}

const latestStateChangeTo = (state: string, report: GetReport) => {
    const dates = report.history.filter((history) => history.new_state === state).map((state) => new Date(state.timestamp))
    if (dates.length === 0) return null
    return max(dates)
}

const attachments = (report: GetReport): StatusReportAttachments => {
    return {attachments: getReportByState(report).status_report_attachments}
}

const summary = (report: GetReport): StatusReportSummary => {
    return getReportByState(report) as StatusReportSummary
}

const states = (getReport: GetReport): StatusReportState[] => {
    const report = getReportByState(getReport)
    const currentStates = report.project_states.map((state) => {
        return {
            id: state.id,
            state: state.state as StatusReportStateValue,
            type: state.type as StatusReportStateType,
            comment: (state as GetReport_releasedOrInReview_report_project_states_status_report_status_report).comment,
            date: new Date(state.status_report.report_date),
            report_id: state.status_report_id,
        }
    })

    const oldStates =
        getReport.status_history?.portfolio_project?.status_reports
            .flatMap((oldReport) =>
                oldReport.project_states.map((state) => {
                    return {
                        id: state.id,
                        state: state.state as StatusReportStateValue,
                        type: state.type as StatusReportStateType,
                        comment: null,
                        date: toDate(oldReport.report_date)!,
                        report_id: state.id,
                    }
                })
            )
            .reverse() || []

    return [...oldStates, ...currentStates]
}

const milestones = (report: GetReport): StatusReportMilestone[] => {
    const combineProgramAndProjectMilestones = (
        programMilestones: StatusReportMilestone[],
        projectMilestones: StatusReportMilestone[] | undefined
    ) => {
        if (projectMilestones) {
            const totalMilestones = programMilestones.concat(projectMilestones)
            return totalMilestones
        } else return []
    }

    if (inReviewOrReleasedReport(report)) {
        const released = inReviewOrReleasedReport(report)!

        return released.milestones.map((milestone) => {
            return new StatusReportMilestone(
                milestone.id,
                milestone.description,
                milestone.status,
                toDate(milestone.planned_date),
                toDate(milestone.actual_date)
            )
        })
    }

    const unreleased = unreleasedOrRejectedReport(report)!
    //If a program report:
    if (reportType(report) === "program") {
        //Milestones on program report itself:
        const programMilestones =
            unreleased.portfolio_program?.milestones.map((milestone) => {
                return new StatusReportMilestone(
                    milestone.issue_id,
                    milestone.summary,
                    milestone.status,
                    toDate(milestone.planned_end_date),
                    toDate(milestone.actual_end_date)
                )
            }) || []
        //Milestones on project reports within the program:
        const projectMilestones = unreleased.portfolio_program?.projects.flatMap((project) => {
            return project.milestones.flatMap((milestone) => {
                return new StatusReportMilestone(
                    milestone.issue_id,
                    milestone.summary,
                    milestone.status,
                    toDate(milestone.planned_end_date),
                    toDate(milestone.actual_end_date)
                )
            })
        })
        //Return cumulative program & project milestones:
        return combineProgramAndProjectMilestones(programMilestones, projectMilestones)
    }
    //If a project report:
    return (
        unreleased.portfolio_project?.milestones.map((milestone) => {
            return new StatusReportMilestone(
                milestone.issue_id,
                milestone.summary,
                milestone.status,
                toDate(milestone.planned_end_date),
                toDate(milestone.actual_end_date)
            )
        }) || []
    )
}

const risks = (report: GetReport): StatusReportRisk[] => {
    const combineProgramAndProjectRisks = (programRisks: StatusReportRisk[] | undefined, projectRisks: StatusReportRisk[] | undefined) => {
        if (projectRisks && programRisks) {
            const totalRisks = programRisks.concat(projectRisks)
            return totalRisks
        } else return []
    }

    if (inReviewOrReleasedReport(report)) {
        const released = inReviewOrReleasedReport(report)!

        if (!released.risks) return [] // Role Statusbericht-Teilberechtigte

        return released.risks.map((risk) => {
            return new StatusReportRisk(
                risk.id,
                risk.status as StatusReportRiskStatus,
                risk.description,
                risk.probability_of_occurrence,
                risk.damage_extent,
                risk.estimated_cost,
                risk.assignee_user,
                toDate(risk.next_assessment),
                risk.countermeasures.map((measure) => {
                    return new StatusReportRiskCountermeasure(
                        measure.id,
                        measure.status,
                        measure.description,
                        measure.assignee_user,
                        toDate(measure.due_date)
                    )
                })
            )
        })
    }

    const unreleased = unreleasedOrRejectedReport(report)!
    if (reportType(report) === "program") {
        //Risks on program report itself:
        const programRisks = unreleased.portfolio_program?.risks.map((risk) => {
            return new StatusReportRisk(
                risk.issue_id,
                risk.status as StatusReportRiskStatus,
                risk.summary,
                risk.probability,
                risk.damage_impact,
                risk.estimated_costs,
                risk.assignee_user,
                toDate(risk.next_assessment),
                risk.countermeasures.map((measure) => {
                    return new StatusReportRiskCountermeasure(
                        measure.issue_id,
                        measure.status,
                        measure.summary,
                        measure.assignee_user,
                        toDate(measure.due_date)
                    )
                })
            )
        })
        //Risks on project reports within the program:
        const projectRisks = unreleased.portfolio_program?.projects.flatMap((project) => {
            return project.risks.flatMap((risk) => {
                return new StatusReportRisk(
                    risk.issue_id,
                    risk.status as StatusReportRiskStatus,
                    risk.summary,
                    risk.probability,
                    risk.damage_impact,
                    risk.estimated_costs,
                    risk.assignee_user,
                    toDate(risk.next_assessment),
                    risk.countermeasures.map((measure) => {
                        return new StatusReportRiskCountermeasure(
                            measure.issue_id,
                            measure.status,
                            measure.summary,
                            measure.assignee_user,
                            toDate(measure.due_date)
                        )
                    })
                )
            })
        })
        //Return cumulative program & project risks:
        return combineProgramAndProjectRisks(programRisks, projectRisks)
    }

    //If a project report:
    return (
        unreleased.portfolio_project?.risks.map((risk) => {
            return {
                id: risk.issue_id,
                status: risk.status as StatusReportRiskStatus,
                description: risk.summary,
                probability_of_occurrence: risk.probability || null,
                damage_extent: risk.damage_impact || null,
                estimated_cost: risk.estimated_costs,
                assignee: risk.assignee_user,
                next_assessment: toDate(risk.next_assessment),
                countermeasures: risk.countermeasures.map((measure) => ({
                    id: measure.issue_id,
                    status: measure.status,
                    description: measure.summary,
                    assignee: measure.assignee_user,
                    due_date: toDate(measure.due_date),
                })),
            }
        }) || []
    )
}

const problems = (report: GetReport): StatusReportProblem[] => {
    const combineProgramAndProjectProblems = (
        programProblems: StatusReportProblem[] | undefined,
        projectProblems: StatusReportProblem[] | undefined
    ) => {
        if (projectProblems && programProblems) {
            const totalProblems = programProblems.concat(projectProblems)
            return totalProblems
        } else if (projectProblems && !programProblems) return projectProblems
        else if (!projectProblems && programProblems) return programProblems
        else return []
    }

    if (inReviewOrReleasedReport(report)) {
        const released = inReviewOrReleasedReport(report)!
        if (!released.problems) return [] // Role Statusbericht-Teilberechtigte
        return released.problems.map((problem) => {
            return {
                id: problem.id,
                name: problem.name,
                assignee: problem.assignee_user,
                status: problem.status,
                due_date: toDate(problem.due_date),
            }
        })
    }

    const unreleased = unreleasedOrRejectedReport(report)!
    if (reportType(report) === "program") {
        // Problems on program report itself:
        const programProblems = unreleased.portfolio_program?.problems.map((problem) => {
            return {
                id: problem.issue_id,
                name: problem.summary,
                assignee: problem.assignee_user,
                status: problem.status,
                due_date: toDate(problem.due_date),
            }
        })
        //Problems on project reports within the program:
        const projectProblems = unreleased.portfolio_program?.projects.flatMap((project) => {
            return (
                project.problems.flatMap((problem) => {
                    return {
                        id: problem.issue_id,
                        name: problem.summary,
                        assignee: problem.assignee_user,
                        status: problem.status,
                        due_date: toDate(problem.due_date),
                    }
                }) || {}
            )
        })
        //Return cumulative program & project problems:
        return combineProgramAndProjectProblems(programProblems, projectProblems)
    }

    //If a project report:
    return (
        unreleased.portfolio_project?.problems.map((problem) => {
            return {
                id: problem.issue_id,
                name: problem.summary,
                assignee: problem.assignee_user,
                status: problem.status,
                due_date: toDate(problem.due_date),
            }
        }) || []
    )
}

export const changeRequests = (report: GetReport): StatusReportChangeRequest[] => {

    const combineProgramAndProjectChangeRequests = (
        programChangeRequests: StatusReportChangeRequest[] | undefined,
        projectChangeRequests: StatusReportChangeRequest[] | undefined
    ) => {
        return ([] as StatusReportChangeRequest[]).concat(programChangeRequests?? []).concat(projectChangeRequests?? [])
    }

    if (inReviewOrReleasedReport(report)) {
        const released = inReviewOrReleasedReport(report)!
        if (!released.change_requests) return [] // Role Statusbericht-Teilberechtigte
        return released.change_requests.map((changeRequest) => {
            return {
                description: changeRequest.description,
                status: changeRequest.status,
                assignee: changeRequest.assignee_user,
                reporter: changeRequest.reporter_user,
            }
        })
    }

    const unreleased = unreleasedOrRejectedReport(report)!
    if (reportType(report) === "program") {
        const program = unreleased.portfolio_program
        // ChangeRequests on program report itself
        const programChangeRequests = program?.change_requests.map((changeRequest) => {
            return {
                description: changeRequest.summary,
                status: changeRequest.status,
                assignee: changeRequest.assignee_user,
                reporter: changeRequest.reporter_user,
            }
        })
        // ChangeRequests on project reports within the program
        const projectChangeRequests = program?.projects.flatMap((project) => {
            return (
                project.change_requests.flatMap((changeRequest) => {
                    return {
                        description: changeRequest.summary,
                        status: changeRequest.status,
                        assignee: changeRequest.assignee_user,
                        reporter: changeRequest.reporter_user,
                    }
                }) || {}
            )
        })
        //Return cumulative program & project problems:
        return combineProgramAndProjectChangeRequests(programChangeRequests, projectChangeRequests)
    }

    // If it is a project report
    return (
        unreleased.portfolio_project?.change_requests.map((changeRequest) => {
            return {
                description: changeRequest.summary,
                status: changeRequest.status,
                assignee: changeRequest.assignee_user,
                reporter: changeRequest.reporter_user,
            }
        }) || []
    )
}

const reportType = (report: GetReport): "program" | "project" => {
    if (inReviewOrReleasedReport(report)) {
        const inReviewOrReleased = inReviewOrReleasedReport(report)!
        return inReviewOrReleased.portfolio_project ? "project" : "program"
    }
    const unreleased = unreleasedOrRejectedReport(report)!
    return unreleased.portfolio_project ? "project" : "program"
}

export const toStatusReportData = (report: GetReport): StatusReportData => {
    const reportOverview = overview(report)
    const reportAttachments = attachments(report)
    const reportSummary = summary(report)
    const reportStates = states(report)
    const reportMilestones = milestones(report)
    const reportRisks = risks(report)
    const reportProblems = problems(report)
    const reportChangeRequests = changeRequests(report)
    const type = reportType(report)

    return new StatusReportData(
        getReportByState(report).id,
        type,
        reportOverview,
        reportAttachments,
        reportSummary,
        reportStates,
        reportMilestones,
        reportRisks,
        reportProblems,
        reportChangeRequests
    )
}
