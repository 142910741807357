import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, makeStyles, withStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {StatusReportChangeRequest} from "../statusReportData"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

interface Props {
    changeRequests: StatusReportChangeRequest[]
}

const useStyles = makeStyles(() =>
    createStyles({
        descTableCell: {},
        statusTableCell: {
            width: "80px",
        },
        userTableCell: {
            width: "250px",
        },
    }),
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#fff",
            },
        },
    }),
)(TableRow)

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}
function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

const ChangeRequests: React.FC<Props> = ({changeRequests}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    const [tabValue, setTabValue] = React.useState(0)
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const getChangeRequestsByStatus = (statuses: string[]) => {
        return changeRequests.filter((changeRequest) => statuses.indexOf(changeRequest.status) >= 0)
    }
    const openChangeRequests = getChangeRequestsByStatus(["New", "In Progress", "In Preparation", "Ready for Approval"])
    const closedChangeRequests = getChangeRequestsByStatus(["Resolved", "Rejected"])

    const ChangeRequestsTable: React.FC<Props> = ({changeRequests}) => {
        return (
            <div className={styles.tableContainer}>
                {changeRequests.length !== 0 && (
                    <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                        <Table size="small" aria-label="simple table"
                               style={{tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.descTableCell}>
                                        {t("short-description")}
                                    </TableCell>
                                    <TableCell align="left" className={classes.statusTableCell}>
                                        {t("status")}
                                    </TableCell>
                                    <TableCell align="right" className={classes.userTableCell}>
                                        {t("reporter")}
                                    </TableCell>
                                    <TableCell align="right" className={classes.userTableCell}>
                                        {t("assignee")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {changeRequests.map((changeRequest, index) => (
                                    <StyledTableRow key={index}
                                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell align="left" className={classes.descTableCell}>
                                            {changeRequest.description}
                                        </TableCell>
                                        <TableCell align="left" className={classes.statusTableCell}>
                                            {changeRequest.status}
                                        </TableCell>
                                        <TableCell align="right" className={classes.userTableCell}>
                                            {changeRequest.reporter?.full_name ?? ""}
                                        </TableCell>
                                        <TableCell align="right" className={classes.userTableCell}>
                                            {changeRequest.assignee?.full_name ?? ""}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {changeRequests.length === 0 && (
                    <div className={styles.noDataAvailable}>
                        <p>{t("no-data-available")}</p>
                    </div>
                )}
            </div>
        )
    }

    return (
        <Box sx={{width: "100%", marginTop: "10px"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="risks tabs">
                    <Tab label={t("open-change-requests")} sx={{fontSize: "0.75rem"}} />
                    <Tab label={t("closed-change-requests")} sx={{fontSize: "0.75rem"}} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ChangeRequestsTable changeRequests={openChangeRequests} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ChangeRequestsTable changeRequests={closedChangeRequests} />
            </TabPanel>
        </Box>
    )
}

export default ChangeRequests
