import React from "react"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Table from "@mui/material/Table"
import Paper from "@mui/material/Paper"
import {PortfolioReportData} from "../portfolioReportData"
import {Overview, toBudgetData} from "./portfolioBudgetData"
import BudgetTableRow from "./BudgetTableRow"
import {useTranslation} from "react-i18next"

interface Props {
    data: PortfolioReportData
    yearsDisplayed?: (period: Overview) => boolean
}

const BudgetTable: React.FC<Props> = ({data, yearsDisplayed}) => {
    const {t} = useTranslation("translations")
    const budgetData = toBudgetData(data, yearsDisplayed)

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="budget table" size="small">
                <colgroup>
                    <col style={{width: "1%"}} />
                    <col style={{width: "19%"}} />
                    <col style={{width: "16%"}} />
                </colgroup>
                <TableHead>
                    <TableRow style={{background: "rgb(238 238 238)"}}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">{t("total")}</TableCell>
                        {budgetData.years.map((year, index) => (
                            <TableCell align="right" key={index}>
                                {year}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <BudgetTableRow
                        title={t("portfolio-report-budget")}
                        data={budgetData.havingBudget().withoutType("program")}
                        amount={(data) => data.budget()}
                        getTotal={(data) => budgetData.sumBudget(data)}
                        getTotalPerRow={(data) => budgetData.sumBudgetRow(data)}
                    />
                    <BudgetTableRow
                        title={t("portfolio-report-budget-table-planned-costs")}
                        data={budgetData.havingPlanned()}
                        amount={(data) => data.planned()}
                        getTotal={(data) => budgetData.sumPlanned(data)}
                        getTotalPerRow={(data) => budgetData.sumPlannedRow(data)}
                    />
                    <BudgetTableRow
                        title={t("portfolio-report-budget-table-actual-costs")}
                        data={budgetData.havingActual()}
                        amount={(data) => data.actual()}
                        getTotal={(data) => budgetData.sumActual(data)}
                        getTotalPerRow={(data) => budgetData.sumActualRow(data)}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BudgetTable
