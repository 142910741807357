import React from "react"
//icon imports
import {library} from "@fortawesome/fontawesome-svg-core"
import {
    faFolderOpen,
    faChartPie,
    faList,
    faChartBar,
    faCalendarAlt,
    faProjectDiagram,
    faThLarge,
    faBriefcase,
    faBuilding,
    faBook,
    faTh,
    faExchangeAlt,
    faChevronLeft,
    faExternalLinkAlt,
    faArchive,
    faTrash,
    faImage,
    faVideo,
    faFileWord,
    faFileExcel,
    faSearch,
    faMinusCircle,
    faCheckCircle,
    faExclamationCircle,
    faBars,
    faEdit,
    faPrint,
    faExternalLinkSquareAlt,
    faBan,
    faFilePdf,
    faFileImage,
    faTasks,
    faArrowsSplitUpAndLeft,
    faTags,
} from "@fortawesome/free-solid-svg-icons"
import {
    faFileChartPie,
    faShareAltSquare,
    faChartNetwork,
    faFileChartLine,
    faLongArrowUp,
    faThumbtack,
    faBullseyePointer,
    faCloudUpload,
    faBoxOpen,
    faAnalytics,
} from "@fortawesome/pro-regular-svg-icons"

//fontawesome icon library
library.add(
    faFileChartPie,
    faShareAltSquare,
    faChartNetwork,
    faFileChartLine,
    faAnalytics,
    faFolderOpen,
    faChartPie,
    faList,
    faChartBar,
    faCalendarAlt,
    faProjectDiagram,
    faThLarge,
    faBriefcase,
    faBuilding,
    faLongArrowUp,
    faBook,
    faTh,
    faExchangeAlt,
    faChevronLeft,
    faThumbtack,
    faExternalLinkAlt,
    faArchive,
    faTrash,
    faBullseyePointer,
    faImage,
    faVideo,
    faFileWord,
    faFileExcel,
    faSearch,
    faMinusCircle,
    faCheckCircle,
    faExclamationCircle,
    faBars,
    faEdit,
    faPrint,
    faExternalLinkSquareAlt,
    faCloudUpload,
    faBoxOpen,
    faBan,
    faFilePdf,
    faFileImage,
    faTasks,
    faArrowsSplitUpAndLeft,
    faTags
)

interface Props {
    children?: React.ReactNode
}

const IconsImportWrapper: React.FC<Props> = ({children}) => {
    return <>{children}</>
}

export default IconsImportWrapper
