import {PortfolioReportData} from "../portfolioReportData"
import {distinct, isPresent} from "../../../../utils/arrays"
import {StatusReportStateType, StatusReportStateValue} from "../../../ProjectManagement/StatusReport/statusReportData"
import {compareAsc, compareDesc, endOfMonth, isAfter, isBefore, isEqual, subYears} from "date-fns"

export const statusTypes: StatusReportStateType[] = ["total", "costs", "schedule", "resources", "scope"]

export class ProjectState {
    constructor(
        public readonly projectId: string,
        public readonly projectName: string,
        public readonly projectState: string,
        public readonly reportDate: Date,
        public readonly type: StatusReportStateType,
        public readonly state: StatusReportStateValue,
        public readonly url: string | null
    ) {
    }

    mapReportData(fn: (date: Date) => Date): ProjectState {
        return new ProjectState(this.projectId, this.projectName, this.projectState, fn(this.reportDate), this.type, this.state, this.url)
    }
}

export class ProjectStateData {
    constructor(public readonly states: ProjectState[]) {
    }

    public mapReportDate(fn: (date: Date) => Date): ProjectStateData {
        return new ProjectStateData(this.states.map(state => state.mapReportData(fn)))
    }

    public notOlderThan(date: Date): ProjectStateData {
        return new ProjectStateData(this.states.filter(state => !isBefore(state.reportDate, date)))
    }

    public notNewerThan(date: Date): ProjectStateData {
        return new ProjectStateData(this.states.filter(state => !isAfter(state.reportDate, date)))
    }

    public withType(type: StatusReportStateType) {
        return new ProjectStateData(this.states.filter(state => state.type === type))
    }

    projectIds(): string[] {
        return this.states.map(state => state.projectId).filter(distinct)
    }

    public groupByProject(): { projectId: string, data: ProjectStateData }[] {
        return this.projectIds().map(id => {
            return {
                projectId: id,
                data: new ProjectStateData(this.states.filter(state => state.projectId === id))
            }
        })
    }

    public projectStatesWithLatestState(state: StatusReportStateValue): ProjectState[] {
        return this.groupByProject().map(project => project.data.latest())
            .filter(isPresent)
            .filter(projectState => projectState.state === state)
    }

    public monthlyProjectStates(): { date: Date, states: ProjectState[] }[] {
        const dates = this.withType("total")
            .mapReportDate(endOfMonth)
            .notOlderThan(subYears(endOfMonth(new Date()), 1))

        return dates
            .groupByDate().map(date => {
                const latestProjectState = dates.notNewerThan(date.date).groupByProject()
                    .map(project => project.data.latest())
                    .filter(isPresent)

                return {
                    date: date.date,
                    states: latestProjectState,
                }
            })
    }
    
    /**
     * Do only use this if #withType was invoked before.
     */
    public latest(): ProjectState | null {
        if (this.isEmpty()) return null
        return this.states.sort((left, right) => compareDesc(left.reportDate, right.reportDate))[0]
    }

    public isEmpty(): boolean {
        return this.states.length === 0
    }

    dates(): Date[] {
        return this.states.map(state => state.reportDate)
            .filter((value, index, array) => array.findIndex((date) => isEqual(date, value)) === index)
            .sort(compareAsc)
    }

    public groupByDate(): { date: Date, data: ProjectStateData }[] {
        return this.dates().map(date => {
            return {
                date: date,
                data: new ProjectStateData(this.states.filter(state => isEqual(state.reportDate, date)))
            }
        })
    }
}

export const toProjectState = (data: PortfolioReportData) => {
    const states = data.projectsAndPrograms().flatMap((project) => {
        return project.status_reports.flatMap((report) =>
            report.project_states.map(
                (state) =>
                    new ProjectState(
                        project.issue_id,
                        project.summary,
                        project.status,
                        new Date(report.report_date),
                        state.type as StatusReportStateType,
                        state.state as StatusReportStateValue,
                        project.overview_url
                    )
            )
        )
    })
    return new ProjectStateData(states)
}
