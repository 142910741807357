import React from "react"
import styles from "../StatusReport.module.scss"

interface Props {
    title: string
}

const StatusReportDatePicker: React.FC<Props> = ({title, children}) => {
    return (
        <div className={styles.reportSection}>
            <div className={styles.reportHeader}>
                <h3>{title}</h3>
                <div className={styles.lineDecoration}></div>
            </div>
            {children}
        </div>
    )
}

export default StatusReportDatePicker
