import React from "react"
import styles from "./App.module.scss"
//icon imports
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useTranslation} from "react-i18next"
//package imports

const NotFound = () => {
    const {t} = useTranslation("translations")

    return (
        <div className={styles.notFoundPage}>
            <FontAwesomeIcon icon="exclamation-circle" size="3x" />
            <h2>{t("page-not-found")}</h2>
        </div>
    )
}

export default NotFound
