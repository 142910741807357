import React from "react"
import TextField from "@mui/material/TextField"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import deSwissLocale from "date-fns/locale/de"

interface Props {
    selectedDate: Date | null
    setNewDate: (value: Date | null) => void
    minDate: Date | undefined | null
}

const StatusReportDatePicker: React.FC<Props> = ({selectedDate, setNewDate, minDate}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deSwissLocale}>
            <DatePicker
                value={selectedDate}
                onChange={(newValue: Date | null) => {
                    setNewDate(newValue)
                }}
                minDate={minDate ?? undefined}
                mask={"__.__.____"}
                OpenPickerButtonProps={{
                    sx: {svg: {height: "1.1rem", width: "1.1rem"}},
                }}
                InputProps={{
                    sx: {backgroundColor: "#f8f8f8", marginRight: "20px"},
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        inputProps={{
                            ...params.inputProps,
                            sx: {
                                maxWidth: "80px",
                                backgroundColor: "#f8f8f8",
                                fontSize: "0.9em",
                                height: "21px",
                                padding: "4.5px 0px 4.5px 14px",
                            },
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

export default StatusReportDatePicker
