import React from "react"
import styles from "../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {StatusReportState, StatusReportStateType, StatusReportStateValue} from "../statusReportData"
import {formatDate} from "../../../../utils/dates"
import {distinct} from "../../../../utils/arrays"
import {compareDesc, isAfter} from "date-fns";

interface Props {
    states: StatusReportState[]
    reportDate: Date
}

const StatusHistory: React.FC<Props> = ({states, reportDate}) => {
    const {t} = useTranslation("translations")

    const statusIconPicker = (state: StatusReportStateValue) => {
        if (state === "in-order") return <FontAwesomeIcon icon="check-circle" style={{color: "#48c792"}}/>
        if (state === "major-difficulties") return <FontAwesomeIcon icon="exclamation-circle"
                                                                    style={{color: "#ed4337"}}/>
        else return <FontAwesomeIcon icon="minus-circle" style={{color: "#dfd139"}}/>
    }

    const types: StatusReportStateType[] = ["costs", "resources", "schedule", "scope", "total"]

    const statesFilteredAndSorted = states.filter(state => !isAfter(state.date, reportDate)).sort(compareDescByDate)

    const headers = () => {
        return statesFilteredAndSorted.map((state) => formatDate(state.date)).filter(distinct)
    }

    const statesFor = (type: StatusReportStateType) => {
        return statesFilteredAndSorted.filter((state) => state.type === type).map((state) => state.state)
    }

    return (
        <div className={styles.tableContainer}>
            {statesFilteredAndSorted.length > 1 && (
                <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                {headers().map((header, index) => {
                                    return (
                                        <TableCell key={index} align="center">
                                            {header}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {types.map((name, index) => {
                                return (
                                    <TableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell component="th" scope="row" sx={{fontWeight: "bold"}}>
                                            {t(name)}
                                        </TableCell>
                                        {statesFor(name).map((state, index) => {
                                            return (
                                                <TableCell key={index} align="center">
                                                    {statusIconPicker(state)}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {statesFilteredAndSorted.length <= 1 && (
                <div className={styles.noDataAvailable}>
                    <p style={{marginTop: "30px"}}>{t("no-data-available")}</p>
                </div>
            )}
        </div>
    )
}

const compareDescByDate = (left: StatusReportState, right: StatusReportState) => {
    return compareDesc(left.date, right.date)
}

export default StatusHistory
