import React from "react"
import styles from "./SelectDropdown.module.scss"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import useMediaQuery from "@mui/material/useMediaQuery"
import {IconName} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"

const selectStyles = {
    backgroundColor: "#f8f8f8",
    fontSize: "0.9em",
    height: "30px",
    minWidth: "125px",
    color: "#606060",
}

interface Props {
    id?: string
    title: string
    handleChange: (event: SelectChangeEvent, type: string) => void
    value: string
    type: string
    options: {name: string; id: string}[]
    icon: string
    isDisabled?: boolean
}

const SelectDropdown: React.FC<Props> = ({id, title, handleChange, value, type, options, icon, isDisabled}) => {
    const isXSScreen = useMediaQuery("(max-width: 600px)")

    return (
        <FormControl size="small" fullWidth={isXSScreen}>
            <Select
                disabled={isDisabled}
                fullWidth={isXSScreen}
                displayEmpty
                id={`${title}-select`}
                value={value}
                onChange={(e) => handleChange(e, type)}
                name={type}
                sx={selectStyles}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            bgcolor: "#f8f8f8",
                            "& .MuiMenuItem-root": {
                                padding: 1,
                                fontSize: "0.95em",
                            },
                        },
                    },
                }}
                renderValue={
                    value
                        ? undefined
                        : () => (
                              <div className={styles.placeholder}>
                                  <FontAwesomeIcon icon={icon as IconName} size="sm" />
                                  <span>{title}</span>
                              </div>
                          )
                }
            >
                {value && (
                    <MenuItem disabled value={""}>
                        <div className={styles.placeholder}>
                            <FontAwesomeIcon icon={icon as IconName} size="sm" />
                            <span>{title}</span>
                        </div>
                    </MenuItem>
                )}

                {options.map((option, index) => {
                    return (
                        <MenuItem key={index} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SelectDropdown
