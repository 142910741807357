import React from "react"
import {StatusReportData} from "../statusReportData"
import {gql, useLazyQuery, useMutation, ApolloQueryResult} from "@apollo/client"
import {DeleteReportAttachment, DeleteReportAttachmentVariables} from "./types/DeleteReportAttachment"
import {GetFileDownload, GetFileDownloadVariables} from "./types/GetFileDownload"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import IconButton from "@mui/material/IconButton"
import styles from "../StatusReport.module.scss"
import axios from "axios"
import {UploadDropzone} from "./UploadDropzone"
import {GetReport, GetReportVariables} from "../types/GetReport"

const deleteReportAttachment = gql`
    mutation DeleteReportAttachment($filename: String, $statusreport_id: uuid) {
        delete_status_report_status_report_attachment(where: {filename: {_eq: $filename}, statusreport_id: {_eq: $statusreport_id}}) {
            affected_rows
        }
    }
`

const getFileDownload = gql`
    query GetFileDownload($filename: String!, $statusreport_id: String!) {
        prepare_attachment_download(filename: $filename, statusreport_id: $statusreport_id) {
            file_id
            filename
            url
        }
    }
`

interface Props {
    report: StatusReportData
    queryRefetch: (variables?: Partial<GetReportVariables> | undefined) => Promise<ApolloQueryResult<GetReport>>
}

const ReportAttachments: React.FC<Props> = ({report, queryRefetch}) => {
    const attachments = report.attachments.attachments

    const [get_file_download] = useLazyQuery<GetFileDownload, GetFileDownloadVariables>(getFileDownload)
    const [handle_delete_attachment] = useMutation<DeleteReportAttachment, DeleteReportAttachmentVariables>(deleteReportAttachment)

    const handleDeleteAttachment = async (filename: string, statusreport_id: string) => {
        await handle_delete_attachment({
            variables: {
                filename,
                statusreport_id,
            },
        })
        queryRefetch()
    }

    const isUnreleasedOrRejected = report.overview.state === "unreleased" || report.overview.state === "rejected"

    const handleFileDownload = async (filename: string, statusreport_id: string) => {
        const res = await get_file_download({variables: {filename, statusreport_id}})
        const url = res.data?.prepare_attachment_download?.url
        url &&
            (await axios.get(url, {responseType: "blob"}).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", filename)
                document.body.appendChild(link)
                link.click()
            }))
    }

    return (
        <div className={styles.tableContainer}>
            <div className={styles.attachmentsContainer}>
                {attachments.map((file, index) => {
                    return (
                        <div key={index} className={styles.attachmentItem}>
                            <p className={styles.attachmentWrapper} onClick={() => handleFileDownload(file.filename, file.statusreport_id)}>
                                <FontAwesomeIcon
                                    style={{color: "grey", marginRight: "10px"}}
                                    icon={file.filename.endsWith(".pdf") ? ["fas", "file-pdf"] : (["fas", "file-image"] as IconProp)}
                                />
                                {file.filename}
                            </p>

                            <span>
                                <IconButton
                                    aria-label="delete"
                                    color="default"
                                    onClick={() => handleDeleteAttachment(file.filename, file.statusreport_id)}
                                >
                                    <FontAwesomeIcon style={{fontSize: "0.9rem"}} icon={["fas", "trash"] as IconProp} />
                                </IconButton>
                            </span>
                        </div>
                    )
                })}
            </div>
            {isUnreleasedOrRejected && <UploadDropzone statusReportId={report.id} refetch={queryRefetch} />}
        </div>
    )
}

export default ReportAttachments
