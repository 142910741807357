import React, {useCallback} from "react"
import {useDropzone, FileRejection} from "react-dropzone"
import styles from "./UploadDropzone.module.scss"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Button from "@mui/material/Button"
import {useTranslation} from "react-i18next"
import {useFeedback} from "../../../../components/Feedback"
import {gql, useLazyQuery, useMutation, ApolloQueryResult} from "@apollo/client"
import {PrepareUpload, PrepareUploadVariables} from "./types/PrepareUpload"
import axios from "axios"
import {
    CreateOrUpdateStatusReportAttachment,
    CreateOrUpdateStatusReportAttachmentVariables,
} from "./types/CreateOrUpdateStatusReportAttachment"
import {GetReport, GetReportVariables} from "../types/GetReport"

const prepareUpload = gql`
    query PrepareUpload($filename: String!, $statusreport_id: String!) {
        prepare_attachment_upload(filename: $filename, statusreport_id: $statusreport_id) {
            url
            filename
            file_id
        }
    }
`

const createOrUpdateStatusReportAttachment = gql`
    mutation CreateOrUpdateStatusReportAttachment($filename: String, $statusreport_id: uuid) {
        insert_status_report_status_report_attachment_one(
            object: {filename: $filename, statusreport_id: $statusreport_id}
            on_conflict: {constraint: status_report_attachment_pkey, update_columns: []}
        ) {
            filename
            statusreport_id
        }
    }
`

interface Props {
    statusReportId: string
    refetch: (variables?: Partial<GetReportVariables> | undefined) => Promise<ApolloQueryResult<GetReport>> //This refetch initiates a re-render when a file is uploaded so the file shows immediately on the UI.
}

export const UploadDropzone: React.FC<Props> = ({statusReportId, refetch}) => {
    const {t} = useTranslation("translations")
    const feedback = useFeedback()

    const maxFileSize = 20971520 //Is a maximum of 20MB in bytes(binary)

    const [get_upload_url] = useLazyQuery<PrepareUpload, PrepareUploadVariables>(prepareUpload)
    const [create_or_update_statusreport_attachment] = useMutation<
        CreateOrUpdateStatusReportAttachment,
        CreateOrUpdateStatusReportAttachmentVariables
    >(createOrUpdateStatusReportAttachment, {
        refetchQueries: ["GetReport"],
    })

    const handleSubmission = async (files: File[]) => {
        const res = await get_upload_url({variables: {filename: files[0].name, statusreport_id: statusReportId}})
        const url = res.data?.prepare_attachment_upload?.url

        url &&
            (await axios
                .put(url, files[0], {headers: {"Content-Type": "application/octet-stream"}})

                .then(async (result) => {
                    await create_or_update_statusreport_attachment({
                        variables: {
                            filename: res.data?.prepare_attachment_upload?.filename,
                            statusreport_id: statusReportId,
                        },
                    })
                    feedback.showSuccess("upload-successful", t("upload-file-success"))
                })
                .catch((error) => {
                    feedback.showError("upload-error", t("upload-file-error"))
                    throw new Error(error)
                }))
    }

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "too-many-files") {
                        feedback.showError("upload-file-error-wrong-too-many", t("upload-file-error-too-many"))
                    }
                    if (err.code === "file-invalid-type") {
                        feedback.showError("upload-file-error-wrong-file-type", t("upload-file-error-file-type"))
                    }
                    if (err.code === "file-too-large") {
                        feedback.showError("upload-file-error-file-size", t("upload-file-error-file-size"))
                    }
                })
            })
            if (acceptedFiles.length) {
                handleSubmission(acceptedFiles)
            }
        },
        [feedback, handleSubmission, t]
    )

    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
        onDrop,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "application/pdf": [],
        },
        minSize: 0,
        maxSize: maxFileSize,
        multiple: false,
        noClick: true,
    })

    return (
        <div className={styles.uploadDropzone}>
            <div {...getRootProps()} className={styles.spaceforBorder}>
                {!isDragActive && (
                    <div className={styles.wrapper}>
                        <div className={styles.titleWrapper}>
                            <FontAwesomeIcon icon={["far", "cloud-upload"] as IconProp} className={styles.uploadIcon} />
                            <p className={styles.title}>{t("upload-files")}</p>
                        </div>

                        <p className={styles.secondaryHelperText}>{t("upload-files-helper-text")}</p>
                        <Button variant="contained" component="label" size="small">
                            {t("select-files")}
                            <input type="file" hidden {...getInputProps()} />
                        </Button>
                    </div>
                )}
                {isDragActive && !isDragReject && (
                    <div className={`${styles.wrapper} ${styles.fileIsValid} `}>
                        <FontAwesomeIcon icon={["far", "box-open"] as IconProp} className={styles.uploadIcon} />
                        <p className={styles.title}>{t("drop-here")}</p>
                    </div>
                )}

                {isDragReject && (
                    <div className={`${styles.wrapper} ${styles.fileIsInvalid} `}>
                        <FontAwesomeIcon icon={["fas", "ban"] as IconProp} className={styles.uploadIcon} />
                        <div className={styles.rejectedFileTypeTextWrapper}>
                            <p>{t("upload-file-error-helpertext")}</p>
                            <span>{"&"}</span>
                            <p>{t("upload-file-error-file-size")}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
