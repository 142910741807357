import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {BrowserRouter as Router} from "react-router-dom"
import * as Sentry from "@sentry/react"

// import i18n (needs to be bundled ;))
import "./i18n"
import {Integrations} from "@sentry/tracing"

Sentry.init({
    dsn: "https://cd5646b684f5420baf3cb202c2403d0e@sentry.linkyard.io/3",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    environment: window.location.host,
    release: (window as any).version,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
})

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
