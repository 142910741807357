import React from "react"
import {ResponsivePie} from "@nivo/pie"
import useMediaQuery from "@mui/material/useMediaQuery"
import {colorSchemes} from "@nivo/colors"
import styles from "../PortfolioReport.module.scss"
import {useTranslation} from "react-i18next"
import {PortfolioReportData} from "../portfolioReportData"
import {GraphDialogProps} from "../PortfolioReportMainContent"
import {CenteredMetric} from "./CenteredMetricForPieCharts"

interface Props {
    data: PortfolioReportData
    setTableDialogOpen?: (value: GraphDialogProps) => void
    printVersion?: boolean
}

const ByKindPieChart: React.FC<Props> = ({data, setTableDialogOpen, printVersion}) => {
    const {t} = useTranslation("translations")

    const isXSScreen = useMediaQuery("(max-width: 600px)")
    const isMediumScreen = useMediaQuery("(max-width: 1200px)")
    const isXXLScreen = useMediaQuery("(min-width: 2000px)")
    const mobileSize = {top: 20, right: 80, bottom: 80, left: 80}

    const determineMargins = () => {
        if (isXSScreen) return {top: 20, right: 20, bottom: 20, left: 20}
        if (printVersion) return {top: -40, right: 100, bottom: 30, left: 20}
        else return mobileSize
    }

    const chartData = [
        {
            id: "ICT",
            label: `${t("kind-ict")}`,
            value: 0,
        },
        {
            id: "Non-ICT",
            label: `${t("kind-non-ict")}`,
            value: 0,
        },
        {
            id: "Keine",
            label: `${t("kind-keine")}`,
            value: 0,
        },
    ]

    const generateChartData = () => {
        data.projectsAndPrograms().forEach((project) => {
            if (project.kind === "" || project.kind === null) {
                const keine = chartData.find((bar) => {
                    return bar.id === "Keine"
                })
                keine!.value += 1
            }

            chartData.forEach((bar) => {
                if (bar.id.toLocaleLowerCase() === project.kind?.toLocaleLowerCase()) {
                    bar.value = bar.value + 1
                }
            })
        })
        return chartData
    }
    return (
        <div className={styles.statusPieWrapper}>
            <ResponsivePie
                layers={["arcs", "arcLabels", "legends", CenteredMetric]}
                animate={!printVersion}
                data={generateChartData()}
                margin={determineMargins()}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={1}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                }}
                theme={{
                    fontSize: 12,
                    labels: {
                        text: {
                            fontWeight: 800,
                            fontSize: 14,
                        },
                    },
                }}
                tooltip={(item) => {
                    return (
                        <div className={styles.graphToolTip}>
                            <div className={styles.colorBox} style={{backgroundColor: `${item.datum.color}`}}></div>

                            <p>
                                {item.datum.label} {` - `}
                                <b>{item.datum.value}</b>
                            </p>
                        </div>
                    )
                }}
                onClick={(node) => {
                    setTableDialogOpen && setTableDialogOpen({graphType: "kind", filterValue: node.id.toString()})
                }}
            />
            <div className={styles.legend}>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: colorSchemes.nivo[0]}}></div>
                    <span>{t(`${chartData[0].label}`)}</span>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: colorSchemes.nivo[1]}}></div>
                    <span>{t(`${chartData[1].label}`)}</span>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: colorSchemes.nivo[2]}}></div>
                    <span>{t(`${chartData[2].label}`)}</span>
                </div>
            </div>
        </div>
    )
}

export default ByKindPieChart
