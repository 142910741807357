import React from "react"
import styles from "../../StatusReport.module.scss"
import Grid from "@mui/material/Grid"
import {formatDate} from "../../../../../utils/dates"

interface DateGridProps {
    name: string
    value: Date | null | undefined
}

const GridItemDateCell: React.FC<DateGridProps> = ({name, value}) => {
    return (
        <Grid item xs={6} sm={6} md={4}>
            <div className={styles.reportTextCell}>
                <div className={styles.reportTextCellTitle}>
                    <p>{name}:</p>
                </div>

                <p>{formatDate(value)}</p>
            </div>
        </Grid>
    )
}

export default GridItemDateCell
