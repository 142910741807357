import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"
import PageWrapper from "../../../components/PageWrapper"
import {DropdownProps} from "../../../components/FilterDropdownsSection"
import FilterDropdownsSection from "../../../components/FilterDropdownsSection"
import ProjectListTable from "./ProjectListTable"
import {gql, useQuery} from "@apollo/client"
import {GetProjectsList} from "./types/GetProjectsList"
import {CircularProgress} from "@mui/material"
import {capitalizeFirstLetter} from "../../../utils/strings"
import {distinct, isPresent} from "../../../utils/arrays"
import {getPageMarginsDataGrid} from "../../../utils/printStyles"
import {useReactToPrint} from "react-to-print"
import {PortfolioAndOrgUnitProjectListFilter} from "./types/PortfolioAndOrgUnitProjectListFilter"

const getPortfoliosAndOrgUnits = gql`
    query PortfolioAndOrgUnitProjectListFilter {
        projects: jira_rm_project_list(distinct_on: organisation_unit) {
            organisation_unit
            portfolio
        }
    }
`

const getProjectsList = gql`
    query GetProjectsList($where: jira_rm_project_list_bool_exp) {
        projects: jira_rm_project_list(where: $where, order_by: {summary: asc}) {
            id: issue_id
            manager
            type
            phase
            kind
            execution_type
            organisation_unit
            summary
            description
            category
            roadmap_relevancy
            planned_end_date
            planned_start_date
            actual_end_date
            actual_start_date
            priority
            overview_url
            sponsor
            size
            status
            portfolio
            cross_section_selections
            theme_tags
            stakeholders
        }
    }
`

const ProjectList = () => {
    const {t} = useTranslation("translations")
    const printStatusReportRef = React.useRef<HTMLDivElement | null>(null)

    const [portfolioOptions, setPortfolioOptions] = useState<{name: string; id: string}[]>([])
    const [orgUnitOptions, setOrgUnitOptions] = useState<{name: string; id: string}[]>([])

    const [overviewFilterOptions, setOverviewFilterOptions] = React.useState({
        portfolio: "",
        status: "",
        organisation_unit: "",
        kind: "",
        category: "",
        type: "",
        cross_section_selection: "",
        execution_type: "",
        theme_tags: "",
    })
    let filterVariables = {}
    Object.entries(overviewFilterOptions)
        .filter((x) => x[1] !== "")
        .forEach((x) => {
            if (x[0] === "cross_section_selection") {
                filterVariables = {
                    ...filterVariables,
                    cross_section_selections: {_contains: x[1]},
                }
            } else if (x[0] === "theme_tags") {
                filterVariables = {
                    ...filterVariables,
                    theme_tags: {_contains: x[1]},
                }
            } else {
                filterVariables = {
                    ...filterVariables,
                    [x[0]]: {_eq: x[1]},
                }
            }
        })

    const handlePrint = useReactToPrint({
        content: () => printStatusReportRef.current,
        documentTitle: t("project-list"),
        //TODO: Space in print view may be caused by the content not loading quick enough before the react-to-print captures the contents of the datagrid:
        // onBeforePrint: () => {
        //     setTimeout(() => {
        //         console.log("delaying...")
        //     }, 100)
        // },
        // pageStyle: `@media print {
        //                        @page {
        //     zoom: 180%;
        //   }
        //                     }`,
    })

    const {
        data: filterData,
        error: orgUnitsError,
    } = useQuery<PortfolioAndOrgUnitProjectListFilter>(getPortfoliosAndOrgUnits)

    const {data, loading, error} = useQuery<GetProjectsList>(getProjectsList, {
        variables: {
            where: filterVariables,
        },
    })

    const createOptions = (array: string[], uppercase?: boolean) => [
        {name: `${t("all")}`, id: ""},
        ...array.map((x) => {
            if (uppercase) {
                return {name: capitalizeFirstLetter(x), id: x}
            }
            return {name: x, id: x}
        }),
    ]

    const createThemeTagOptions = (tags: string[]) => [
        {name: `${t("all")}`, id: ""},
        ...tags.map((tag) => {
            return {name: tag, id: tag}
        }),
    ]

    const projects = filterData?.projects ?? []

    const portfolios =
        projects
            .map((project) => project.portfolio)
            .filter(isPresent)
            .filter(distinct) ?? []

    const themeTags =
        data?.projects
            .flatMap((project) => project.theme_tags)
            .filter(isPresent)
            .filter(distinct) ?? []

    const allOrgUnits =
        projects
            .flatMap((project) => project.organisation_unit)
            .filter(isPresent)
            .filter(distinct) ?? []

    const orgUnitsByPortfolio = new Map<string, string[]>()
    projects
        .filter((pro) => pro.portfolio && pro.organisation_unit)
        .forEach((project) => {
            const units = orgUnitsByPortfolio.get(project.portfolio!)
            if (units === undefined) orgUnitsByPortfolio.set(project.portfolio!, [project.organisation_unit!])
            else if (!units.includes(project.organisation_unit!)) units.push(project.organisation_unit!)
        })

    useEffect(() => {
        if (!filterData) return

        if (overviewFilterOptions.portfolio) {
            const selectedPortfolio = orgUnitsByPortfolio.get(overviewFilterOptions.portfolio)

            if (selectedPortfolio) {
                setOrgUnitOptions(createOptions(selectedPortfolio))
            }
        } else {
            setOrgUnitOptions(createOptions(allOrgUnits))
        }

        if (!overviewFilterOptions.organisation_unit) {
            setPortfolioOptions(createOptions(portfolios))
        } else {
            const ports: string[] = []
            orgUnitsByPortfolio.forEach((orgUnits, portfolio) => {
                if (orgUnits.includes(overviewFilterOptions.organisation_unit)) {
                    ports.push(portfolio)
                }
            })
            setPortfolioOptions(createOptions(ports))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData, overviewFilterOptions])

    useEffect(() => {
        const selectedPortfolio = orgUnitsByPortfolio.get(overviewFilterOptions.portfolio)
        if (selectedPortfolio && !selectedPortfolio.includes(overviewFilterOptions.organisation_unit)) {
            setOverviewFilterOptions({
                ...overviewFilterOptions,
                organisation_unit: "",
            })
        }
    }, [overviewFilterOptions.portfolio])

    if (loading || !data || !filterData) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-spinner">
                <CircularProgress />
            </div>
        )
    }
    if (error || orgUnitsError) throw Error(error?.message || orgUnitsError?.message)

    const dropdowns: DropdownProps[] = [
        {
            title: "portfolio",
            options: portfolioOptions,
            icon: "building",
            type: "portfolio",
        },
        {
            title: "organization_unit",
            options: orgUnitOptions,
            icon: "book",
            type: "organisation_unit",
        },
        {
            title: "status",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("status-in-bearbeitung"), id: "In Bearbeitung"},
                {name: t("status-identifiziert"), id: "Identifiziert"},
                {name: t("status-vorbereitung"), id: "Vorbereitung"},
                {name: t("status-sistiert"), id: "Sistiert"},
                {name: t("status-storniert"), id: "Storniert"},
                {name: t("status-abgebrochen"), id: "Abgebrochen"},
                {name: t("status-abgeschlossen"), id: "Abgeschlossen"},
            ],
            icon: "tasks",
            type: "status",
        },
        {
            title: "category",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("category-digitalisierung"), id: "Digitalisierung"},
                {name: t("category-digitalisierung-schwerpunkt"), id: "Digitalisierung Schwerpunkt"},
            ],
            icon: "th",
            type: "category",
        },
        {
            title: "kind",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("kind-non-ict"), id: "Non-ICT"},
                {name: t("kind-ict"), id: "ICT"},
            ],
            icon: "th-large",
            type: "kind",
        },
        {
            title: "type",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("type-life-cycle"), id: "Life Cycle"},
                {name: t("type-innovation"), id: "Innovation"},
                {name: t("type-governance"), id: "Governance"},
            ],
            icon: "book",
            type: "type",
        },
        {
            title: "cross_section_selection",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("cross-section-allgemeine-verwaltung"), id: "Allgemeine Verwaltung"},
                {
                    name: t("cross-section-öffentliche-ordnung-bevölkerung-sicherheit-verteidigung"),
                    id: "Öffentliche Ordnung, Bevölkerung, Sicherheit, Verteidigung",
                },
                {name: t("cross-section-bildung"), id: "Bildung"},
                {name: t("cross-section-kultur-sport-und-freizeit-kirche"), id: "Kultur, Sport und Freizeit, Kirche"},
                {name: t("cross-section-gesundheit"), id: "Gesundheit"},
                {name: t("cross-section-soziale-sicherheit"), id: "Soziale Sicherheit"},
                {
                    name: t("cross-section-verkehr-und-nachrichtenübermittlung"),
                    id: "Verkehr und Nachrichtenübermittlung",
                },
                {name: t("cross-section-umweltschutz-und-raumordnung"), id: "Umweltschutz und Raumordnung"},
                {name: t("cross-section-volkswirtschaft"), id: "Volkswirtschaft"},
                {name: t("cross-section-finanzen-und-steuern"), id: "Finanzen und Steuern"},
                {name: t("cross-section-bau--und-wohnungswesen"), id: "Bau- und Wohnungswesen"},
                {name: t("cross-section-geschäftspartner"), id: "Geschäftspartner"},
                {name: t("cross-section-geographie"), id: "Geographie"},
                {name: t("cross-section-statistik"), id: "Statistik"},
                {name: t("cross-section-kdi-relevanz"), id: "KDI-Relevanz"},
            ],

            icon: "th",
            type: "cross_section_selection",
        },
        {
            title: "execution_type",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("execution-type-agile"), id: "Agil"},
                {name: t("execution-type-hybrid"), id: "Hybrid"},
                {name: t("execution-type-classic"), id: "Klassisch"},
            ],
            icon: "fa-arrows-split-up-and-left",
            type: "execution_type",
        },
        {
            title: "theme_tags",
            options: createThemeTagOptions(themeTags),
            icon: "tags",
            type: "theme_tags",
        },
    ]

    const handleChange = (event: SelectChangeEvent) => {
        setOverviewFilterOptions({...overviewFilterOptions, [event.target.name]: event.target.value})
    }

    return (
        <>
            <style>{getPageMarginsDataGrid()}</style>
            <div ref={printStatusReportRef}>
                <PageWrapper headerTitle={t("project-list")} icon={"list" as IconProp}>
                    <FilterDropdownsSection
                        handleChange={handleChange}
                        filterValues={overviewFilterOptions}
                        dropdowns={dropdowns}
                        handlePrint={handlePrint}
                    />

                    <ProjectListTable projects={data.projects} />
                </PageWrapper>
            </div>
        </>
    )
}

export default ProjectList
