import React from "react"
import styles from "./PortfolioReport.module.scss"
import {useTranslation} from "react-i18next"
import Grid from "@mui/material/Grid"
import PortfolioReportProjectListTable from "./PortfolioReportProjectListTable"
import {Dialog} from "@mui/material"
import {PortfolioReportData} from "./portfolioReportData"
import {GraphDialogProps} from "./PortfolioReportMainContent"
import {toProjectState} from "./Charts/projectStateData"
import {formatStringForTranslation} from "../../../utils/strings"

interface Props {
    dialogData: GraphDialogProps | null
    handleCloseTableDialog: () => void
    data: PortfolioReportData
}

const GraphProjectsDialog: React.FC<Props> = ({dialogData, handleCloseTableDialog, data}) => {
    const {t} = useTranslation("translations")

    if (!dialogData) return <></>

    const graphType = dialogData.graphType
    const filterValue = dialogData.filterValue
    const programOrProject = dialogData.programOrProject || null
    const statusType = dialogData.statusType
    const totalStatusData = dialogData.totalStatusData

    const determineFilter = () => {
        if (programOrProject === "program") return (graphType && data.filterPrograms(graphType, filterValue)) || []
        if (programOrProject === "project") return (graphType && data.filterProjects(graphType, filterValue)) || []
        if (filterValue.toLowerCase() === "keine" && graphType) {
            return data.filterProjectsAndPrograms(graphType, null)
        }
        return (graphType && data.filterProjectsAndPrograms(graphType, filterValue)) || []
    }

    const statusFilter = () => {
        const statesData = toProjectState(data).withType(statusType!)
        const result = statesData.groupByProject().filter((project) => {
            return formatStringForTranslation(project.data.latest()?.state || "") === formatStringForTranslation(filterValue)
        })
        return result
    }

    return (
        <Dialog onClose={handleCloseTableDialog} open={!!dialogData} maxWidth="md">
            <Grid item container spacing={1} className={`${styles.gridContainerSection}`}>
                <Grid item xs={12} sx={{textAlign: "left"}}>
                    <div className={styles.reportHeader}>
                        <h3>{t("portfolio-report-portfolio-list")}</h3>
                        <div className={styles.lineDecoration}></div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <p className={styles.descriptionText}>{t("project-list-description-text")}</p>
                </Grid>
                <Grid item xs={12}>
                    <PortfolioReportProjectListTable
                        data={determineFilter()}
                        stateData={graphType === "status" && statusType ? statusFilter() : undefined}
                        dialogData={dialogData}
                        totalStatusData={graphType === "status-total" && totalStatusData ? totalStatusData : undefined}
                    />
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default GraphProjectsDialog
