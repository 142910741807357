import React from "react"
import {useTranslation} from "react-i18next"
import SummaryTextCell from "./EditComponents/SummaryTextCell"
import {StatusReportData} from "../statusReportData"
import {gql, useMutation} from "@apollo/client"
import {UpdateReportSummary, UpdateReportSummaryVariables} from "./types/UpdateReportSummary"

const updateReportSummary = gql`
    mutation UpdateReportSummary($report_id: uuid!, $next_milestones: String, $past_successes: String, $description: String) {
        update_status_report_status_report_by_pk(
            pk_columns: {id: $report_id}
            _set: {next_milestones: $next_milestones, past_successes: $past_successes, description: $description}
        ) {
            next_milestones
            past_successes
            description
        }
    }
`

interface Props {
    report: StatusReportData
    queryRefetch: any
}

export interface SummaryEditTypes {
    report_id: string
    next_milestones: string | null
    past_successes: string | null
    description: string | null
}

const ReportSummary: React.FC<Props> = ({report, queryRefetch}) => {
    const {t} = useTranslation("translations")
    const [summaryValues, setSummaryValues] = React.useState<SummaryEditTypes>({
        report_id: report.id,
        next_milestones: report.summary.next_milestones,
        past_successes: report.summary.past_successes,
        description: report.summary.description,
    })

    const [update_report_summary] = useMutation<UpdateReportSummary, UpdateReportSummaryVariables>(updateReportSummary)

    const handleUpdateSummary = async (summaryEditedFields: SummaryEditTypes) => {
        await update_report_summary({
            variables: {
                report_id: summaryEditedFields.report_id,
                next_milestones: summaryEditedFields.next_milestones,
                past_successes: summaryEditedFields.past_successes,
                description: summaryEditedFields.description,
            },
        })
        queryRefetch()
    }

    const gridItemPropPack = {
        handleUpdateSummary: handleUpdateSummary,
        report: report,
        summaryValues: summaryValues,
        setSummaryValues: setSummaryValues,
    }

    return (
        <>
            <SummaryTextCell {...gridItemPropPack} fieldType="description" name={t("short-overview")} value={report.summary.description} />
            <SummaryTextCell
                {...gridItemPropPack}
                fieldType="past_successes"
                name={t("successes-in-last-month")}
                value={report.summary.past_successes}
            />
            <SummaryTextCell
                {...gridItemPropPack}
                fieldType="next_milestones"
                name={t("next-milestones")}
                value={report.summary.next_milestones}
            />
        </>
    )
}

export default ReportSummary
