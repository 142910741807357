import {makeStyles} from "@mui/styles"

export const useCollapseStyles = makeStyles(() => ({
    cell: {
        width: 100,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: "#f8f8f8",
    },
    row: {
        height: 50,
        backgroundColor: "#f8f8f8",
    },
    childRow: {
        transition: "200ms",
    },
    closeRow: {
        height: 0,
    },
    closeCell: {
        border: "none",
    },
}))
