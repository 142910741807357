import React from "react"
import styles from "./App.module.scss"
import i18next from "i18next"
import {
    Route,
    Routes,
    // useLocation
} from "react-router-dom"
// import {TransitionGroup, CSSTransition} from "react-transition-group"
import ProjectList from "./pages/PortfolioAnalysis/ProjectList/ProjectList"
import Overview from "./pages/PortfolioAnalysis/Overview/Overview"
import SharedFiles from "./pages/ProjectManagement/SharedFiles/SharedFiles"
import ProjectStatusDevelopment from "./pages/PortfolioAnalysis/ProjectStatusDevelopment/ProjectStatusDevelopment"
import PlanBudgetActualComparison from "./pages/PortfolioAnalysis/PlanBudgetActualComparison/PlanBudgetActualComparison"
import PortfolioReport from "./pages/PortfolioAnalysis/PortfolioReport/PortfolioReport"
import {FeaturesToggleContext} from "./App"
import NotFound from "./NotFound"
import {useTranslation} from "react-i18next"
import StatusReportViewer from "./pages/ProjectManagement/StatusReport/StatusReportViewer"

const MainContent: React.FC = () => {
    // let location = useLocation()

    const {i18n} = useTranslation("home")
    const featuresToggle = React.useContext(FeaturesToggleContext)

    return (
        <div className={styles.mainContent}>
            {/* TODO: The below code is for transtion animation when navigating between pages. Doesn't work great due to issues with the new v6 react-router. The page the user leaves from stacks below the one clicked on. Maybe find another solution. */}
            {/* <TransitionGroup component={null}>
                <CSSTransition key={location.key} classNames="fade" timeout={300}> */}
            <Routes
            // location={location}
            >
                <Route path="/" element={<StatusReportViewer />} />
                <Route path="/project-management/project-status-report/" element={<StatusReportViewer />} />
                <Route path="/project-management/project-status-report/:reportId" element={<StatusReportViewer />} />

                {featuresToggle.sharedfiles && <Route path="/project-management/shared-files" element={<SharedFiles />} />}
                {featuresToggle.projectList && <Route path="/portfolio-analysis/project-list" element={<ProjectList />} />}
                {featuresToggle.overview && <Route path="/portfolio-analysis/overview" element={<Overview />} />}
                {featuresToggle.projectStatusDev && (
                    <Route path="/portfolio-analysis/project-status-development" element={<ProjectStatusDevelopment />} />
                )}
                {featuresToggle.planBudgetActualComparison && (
                    <Route path="/portfolio-analysis/plan-budget-actual-comparison" element={<PlanBudgetActualComparison />} />
                )}
                {featuresToggle.portfolioReport && <Route path="/portfolio-analysis/portfolio-report" element={<PortfolioReport />} />}
                <Route path="*" element={<NotFound />} />
            </Routes>
            {/* </CSSTransition>
            </TransitionGroup> */}

            <div className={styles.langSwitcher}>
                <select
                    name="lang"
                    onChange={(e) => i18next.changeLanguage(e.target.value)}
                    defaultValue={i18n.language}
                    className={styles.langSelect}
                >
                    <option value="de">🇩🇪</option>
                    <option value="fr">🇫🇷</option>
                    <option value="en">🇬🇧</option>
                </select>
            </div>
        </div>
    )
}

export default MainContent
