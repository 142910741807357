import {ResponsiveScatterPlot} from "@nivo/scatterplot"

const data = [
    {
        id: "Aufbau eines neuen Fachbereichs Arbeitsplatz",
        data: [
            {
                x: 4.3,
                y: 1.6,
                z: 14,
            },
        ],
    },
    {
        id: "Serverraumabbau EG KAIO",
        data: [
            {
                x: 4.5,
                y: 1.3,
                z: 12,
            },
        ],
    },
    {
        id: "Programmleitung Umsetzung ICT-Strategie BE 2021-2025",
        data: [
            {
                x: 2,
                y: 2.5,
                z: 5,
            },
        ],
    },
    {
        id: "Digitale Unterschrift",
        data: [
            {
                x: 3.3,
                y: 4.6,
                z: 7,
            },
        ],
    },
    {
        id: "Virtuelles Whiteboard",
        data: [
            {
                x: 5.4,
                y: 4.9,
                z: 14,
            },
        ],
    },
    {
        id: "Redesign Service- und Applikationsportfolio",
        data: [
            {
                x: 2.9,
                y: 3.6,
                z: 9,
            },
        ],
    },
    {
        id: "Mobile App",
        data: [
            {
                x: 5.6,
                y: 6.0,
                z: 23,
            },
        ],
    },
    {
        id: "BE-Print",
        data: [
            {
                x: 4.2,
                y: 5.2,
                z: 39,
            },
        ],
    },

    {
        id: "Weiterentwicklung BE-Login",
        data: [
            {
                x: 5.2,
                y: 6.9,
                z: 14,
            },
        ],
    },
    {
        id: "SSL Inspection",
        data: [
            {
                x: 2.9,
                y: 5.9,
                z: 9,
            },
        ],
    },
    {
        id: "Redesign Service- und Applikationsportfolio",
        data: [
            {
                x: 4.4,
                y: 4.2,
                z: 9,
            },
        ],
    },
    {
        id: "WebApp Digiz",
        data: [
            {
                x: 3.6,
                y: 6.8,
                z: 5,
            },
        ],
    },
]

const PortfolioEvaluation = () => {
    return (
        <ResponsiveScatterPlot
            animate
            data={data}
            margin={{top: 30, right: 300, bottom: 30, left: 40}}
            xScale={{type: "linear", min: 0, max: "auto"}}
            xFormat=" >-.2f"
            yScale={{type: "linear", min: 0, max: "auto"}}
            yFormat=">-.2f"
            blendMode="multiply"
            nodeSize={{key: "x", values: [6, 500], sizes: [9, 32]}}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Risikopuntzahl",
                legendPosition: "middle",
                legendOffset: 46,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Wertpunktzahl",
                legendPosition: "middle",
                legendOffset: -60,
            }}
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 150,
                    translateY: -40,
                    itemWidth: 100,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: "left-to-right",
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    )
}

export default PortfolioEvaluation
