import {KeycloakInstance} from "keycloak-js"

export interface AuthUser {
    id: string
    email: string
    emailVerified: boolean
    displayName: string
    idToken: string
}

export class Auth {
    constructor(private keycloak: KeycloakInstance) {
    }

    public current(): AuthUser | null {
        const profile = this.keycloak.idTokenParsed as any
        if (!this.keycloak.idToken || !profile) return null

        return {
            id: profile.sub!,
            email: profile.email!,
            emailVerified: !!profile.email_verified,
            displayName: profile.name || `${profile.first_name} ${profile.last_name}`.trim(),
            idToken: this.keycloak.idToken,
        }
    }

    login() {
        this.keycloak.login()
    }

    logout() {
        this.keycloak.logout()
    }
}
