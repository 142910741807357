import {formatStringDateFromStringOrElse} from "../../../utils/dates"
import {FilterPortfolios_programs, FilterPortfolios_projects} from "../PortfolioReport/types/FilterPortfolios"
import {GetProjectsList_projects} from "../ProjectList/types/GetProjectsList"

type Project = GetProjectsList_projects | FilterPortfolios_projects | FilterPortfolios_programs

const determineStartDate = (project: Project) => {
    if (project.actual_start_date) {
        return <span>{`${formatStringDateFromStringOrElse(project["actual_start_date"], "?")}`}</span>
    } else if (project.planned_start_date) {
        return <span>{`${formatStringDateFromStringOrElse(project["planned_start_date"], "?")}`}</span>
    } else {
        return <span>?</span>
    }
}

const determineEndDate = (project: Project) => {
    if (project.actual_end_date) {
        return <span>{`${formatStringDateFromStringOrElse(project["actual_end_date"], "?")}`}</span>
    } else if (project.planned_end_date) {
        return <span>{`${formatStringDateFromStringOrElse(project["planned_end_date"], "?")}`}</span>
    } else {
        return <span>?</span>
    }
}

export const determineDuration = (project: Project) => {
    return (
        <>
            {determineStartDate(project)} - {determineEndDate(project)}
        </>
    )
}
