import React from "react"
import styles from "./App.module.scss"
import Sidebar from "./Sidebar"
import MainContent from "./MainContent"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import IconsImportWrapper from "./IconsImportWrapper"
import {ApolloProvider} from "@apollo/client"
import {createApollo} from "./apollo/Apollo"
import useMediaQuery from "@mui/material/useMediaQuery"
import {FeedbackProvider} from "./components/Feedback"
import {AuthProvider, useAuth} from "./auth/AuthProvider"
import {EnforceLogin} from "./auth/EnforceLogin"
import {ConfigurationContextFetch, useConfig} from "./utils/configProvider"
import {UserProvider} from "./auth/UserProvider"
import {ErrorBoundary, FallbackProps} from "react-error-boundary"
import {ErrorDisplay} from "./ErrorDisplay"

export const FeaturesToggleContext = React.createContext({
    sharedfiles: false,
    projectList: true,
    overview: false,
    projectStatusDev: false,
    planBudgetActualComparison: false,
    portfolioReport: true,
})

const Content: React.FC = () => {
    const isSmallScreen = useMediaQuery("(max-width: 800px)")
    const [sidebarToggle, setSidebarToggle] = React.useState<boolean>(false)

    return (
        <div className={styles.app}>
            {isSmallScreen ? (
                <>
                    {!sidebarToggle && (
                        <div className={styles.mobileSidebarToggleIcon}
                             onClick={() => setSidebarToggle(!sidebarToggle)}>
                            <FontAwesomeIcon icon="bars" size="2x" />
                        </div>
                    )}
                    {sidebarToggle && <Sidebar setSidebarToggle={setSidebarToggle} />}
                </>
            ) : (
                <Sidebar />
            )}
            <MainContent />
        </div>
    )
}

const WithApollo: React.FC = ({children}) => {
    const auth = useAuth()
    const config = useConfig()
    const apollo = createApollo({url: config.hasuraUrl, adminSecret: config.hasuraAdminSecret}, auth)
    return <ApolloProvider client={apollo}>{children}</ApolloProvider>
}

const ErrorFallback = ({error}: FallbackProps) => <ErrorDisplay message={error.message} />

const App: React.FC = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <IconsImportWrapper>
                <ConfigurationContextFetch>
                    <FeedbackProvider>
                        <AuthProvider>
                            <EnforceLogin>
                                <WithApollo>
                                    <UserProvider>
                                        <Content />
                                    </UserProvider>
                                </WithApollo>
                            </EnforceLogin>
                        </AuthProvider>
                    </FeedbackProvider>
                </ConfigurationContextFetch>
            </IconsImportWrapper>
        </ErrorBoundary>
    )
}

export default App
