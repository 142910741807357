import React from "react"
import styles from "../../StatusReport.module.scss"
import {OverviewEditTypes} from "../ReportOverview"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import TextField from "@mui/material/TextField"
import {useAuth} from "../../../../../auth/AuthProvider"
import {StatusReportData} from "../../statusReportData"
import {useTranslation} from "react-i18next"

interface GridItemProps {
    name: string
    value: string
    editableOverviewValues: OverviewEditTypes
    setEditableOverviewValues: (value: OverviewEditTypes) => void
    report: StatusReportData
    handleUpdateOverview?: () => Promise<void>
    fieldType?: string
    editable?: boolean
}

const GridItemTextCell: React.FC<GridItemProps> = ({
    name,
    value,
    fieldType,
    editable,
    setEditableOverviewValues,
    editableOverviewValues,
    handleUpdateOverview,
    report,
}) => {
    const [editToggle, setEditToggle] = React.useState<boolean>(false)
    const auth = useAuth()
    const {t} = useTranslation("translations")

    const handleClose = (e: MouseEvent | TouchEvent | React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setEditToggle(false)
        if (editable && handleUpdateOverview) {
            handleUpdateOverview()
        }
    }
    const isReportEditable = report.isEditableBy(auth.current())

    const displayValue = name === "state" ? t(value.toString()) : value

    return (
        <Grid item xs={6} sm={6} md={4}>
            <div className={styles.reportTextCell} onDoubleClick={editable ? () => setEditToggle(true) : undefined}>
                <div className={styles.reportTextCellTitle}>
                    <p>{name}:</p>
                    {editable && isReportEditable && (
                        <IconButton aria-label="edit" onClick={editable ? () => setEditToggle(true) : undefined}>
                            <FontAwesomeIcon icon="edit" />
                        </IconButton>
                    )}
                </div>

                {editToggle && isReportEditable && fieldType ? (
                    <ClickAwayListener onClickAway={(e) => handleClose(e)} mouseEvent="onMouseUp" touchEvent="onTouchStart">
                        {/* // margin here needed to keep the input at the same positiion as the text before it */}
                        <div style={{marginTop: "10px"}}>
                            <TextField
                                key={"edit-" + fieldType}
                                autoFocus
                                id="value-textfield"
                                variant="outlined"
                                value={editableOverviewValues[fieldType as keyof OverviewEditTypes] || ""}
                                onChange={(e) => setEditableOverviewValues({...editableOverviewValues, [fieldType]: e.currentTarget.value})}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") handleClose(e)
                                }}
                                inputProps={{
                                    sx: {padding: "0px 10px", fontsize: "1rem"},
                                }}
                            />
                        </div>
                    </ClickAwayListener>
                ) : (
                    <p>{fieldType ? editableOverviewValues[fieldType as keyof OverviewEditTypes] : displayValue}</p>
                )}
            </div>
        </Grid>
    )
}

export default GridItemTextCell
