import React from "react"
import styles from "../../StatusReport.module.scss"
import IconButton from "@mui/material/IconButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import TextField from "@mui/material/TextField"
import {SummaryEditTypes} from "../ReportSummary"
import {StatusReportData} from "../../statusReportData"
import {useAuth} from "../../../../../auth/AuthProvider"

interface SummaryItemProps {
    name: string
    value: string | null | number
    fieldType: string
    handleUpdateSummary: (summaryEditedFields: SummaryEditTypes) => Promise<void>
    summaryValues: SummaryEditTypes
    setSummaryValues: (value: SummaryEditTypes) => void
    report: StatusReportData
}

const SummaryTextCell: React.FC<SummaryItemProps> = ({
    name,
    value,
    fieldType,
    handleUpdateSummary,
    summaryValues,
    setSummaryValues,
    report,
}) => {
    const [editToggle, setEditToggle] = React.useState<boolean>(false)
    const auth = useAuth()

    const handleClose = (e: MouseEvent | TouchEvent | React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setEditToggle(false)
        handleUpdateSummary(summaryValues)
    }

    const isReportEditable = report.isEditableBy(auth.current())

    return (
        <div className={styles.reportTextParagraph} onDoubleClick={() => setEditToggle(true)}>
            <div className={styles.reportTextCellTitle}>
                <p>{name}:</p>
                {isReportEditable && (
                    <IconButton aria-label="edit" onClick={() => setEditToggle(true)}>
                        <FontAwesomeIcon icon="edit" />
                    </IconButton>
                )}
            </div>

            {editToggle && isReportEditable ? (
                <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                    {/* // margin here needed to keep the input at the same positiion as the text before it */}
                    <div style={{marginTop: "10px"}}>
                        <TextField
                            multiline
                            autoFocus
                            fullWidth
                            id="value-textfield"
                            variant="outlined"
                            value={summaryValues[fieldType as keyof SummaryEditTypes]}
                            onChange={(e) => setSummaryValues({...summaryValues, [fieldType]: e.target.value})}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && e.shiftKey) {
                                    return //This prevents the submission on just "Enter", allows for new line to be made by first checking if Enter & Shift are pressed.
                                } else if (e.key === "Enter") handleClose(e)
                            }}
                        />
                    </div>
                </ClickAwayListener>
            ) : (
                <p style={{whiteSpace: "pre-wrap"}}>{value}</p>
            )}
        </div>
    )
}

export default SummaryTextCell
