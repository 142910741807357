import {
    FilterPortfolios,
    FilterPortfolios_programs,
    FilterPortfolios_programs_status_reports,
    FilterPortfolios_projects,
    FilterPortfolios_projects_status_reports,
} from "./types/FilterPortfolios"

export class PortfolioReportData {
    constructor(public readonly portfolios: FilterPortfolios | undefined) {}

    public numberOfProjects(): number {
        return this.portfolios?.projects.length ?? 0
    }

    public numberOfPrograms(): number {
        return this.portfolios?.programs.length ?? 0
    }

    public projects(): FilterPortfolios_projects[] {
        return this.portfolios?.projects ?? []
    }

    public programs(): FilterPortfolios_programs[] {
        return this.portfolios?.programs ?? []
    }

    public projectsAndPrograms(): (FilterPortfolios_programs | FilterPortfolios_projects)[] {
        return [...this.programs(), ...this.projects()] ?? []
    }

    public reports(): (FilterPortfolios_programs_status_reports | FilterPortfolios_projects_status_reports)[] {
        return this.projectsAndPrograms().flatMap((pro) => pro.status_reports)
    }

    public filterProjects(field: string, value: string | null): FilterPortfolios_projects[] {
        return this.projects().filter((project) => {
            if (typeof project[field as keyof FilterPortfolios_programs] === "string") {
                return (project[field as keyof FilterPortfolios_programs] as string).toLowerCase() === value?.toLowerCase()
            } else {
                return (project[field as keyof FilterPortfolios_programs] as string) === value
            }
        })
    }

    public filterPrograms(field: string, value: string | null): FilterPortfolios_programs[] {
        return this.programs().filter((program) => {
            if (typeof program[field as keyof FilterPortfolios_programs] === "string") {
                return (program[field as keyof FilterPortfolios_programs] as string).toLowerCase() === value?.toLowerCase()
            } else {
                return (program[field as keyof FilterPortfolios_programs] as string) === value
            }
        })
    }

    public filterProjectsAndPrograms(field: string, value: string | null): (FilterPortfolios_programs | FilterPortfolios_projects)[] {
        return this.projectsAndPrograms().filter((projectOrProgram) => {
            if (
                typeof projectOrProgram[field as keyof FilterPortfolios_programs] === "string" ||
                typeof projectOrProgram[field as keyof FilterPortfolios_projects] === "string"
            ) {
                return (projectOrProgram[field as keyof FilterPortfolios_programs] as string).toLowerCase() === value?.toLowerCase()
            } else {
                return (projectOrProgram[field as keyof FilterPortfolios_programs] as string) === value
            }
        })
    }
}
