import {ApolloClient, ApolloLink, GraphQLRequest, HttpLink, InMemoryCache} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"
import {Auth} from "../auth/auth"

const resolvers = {}

interface ApolloConfig {
    url: string
    adminSecret?: string
}

function roleFor(operation: GraphQLRequest) {
    if (operation.operationName === "FilterPortfolios") return "portfolio_viewer"
    return "cockpit-user"
}

export function createApollo(config: ApolloConfig, auth: Auth) {
    if (!config || !config.url) throw new Error("missing configuration for hasura")
    const httpLink = new HttpLink({uri: config.url})

    const jwtSetter = setContext((operation, previousContext) => {
        const {headers} = previousContext
        if (config.adminSecret) {
            return {
                ...previousContext,
                headers: {
                    ...headers,
                    "x-hasura-admin-secret": config.adminSecret,
                    "x-hasura-user-id": auth.current()?.id,
                    "x-hasura-user-email": auth.current()?.email,
                    "x-hasura-role": roleFor(operation),
                },
            }
        }

        const jwt = auth.current()?.idToken
        if (!jwt) return previousContext // without a user

        return {
            ...previousContext,
            headers: {
                ...headers,
                Authorization: `Bearer ${jwt}`,
                "x-hasura-role": roleFor(operation),
            },
        }
    })

    const link = ApolloLink.from([jwtSetter, httpLink])

    return new ApolloClient({
        link,
        cache: new InMemoryCache(),
        resolvers,
    })
}
