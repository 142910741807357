import React from "react"
//style imports
//package imports
import {useTranslation} from "react-i18next"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"
//component imports
import PageWrapper from "../../../components/PageWrapper"
import {DropdownProps} from "../../../components/FilterDropdownsSection"
import FilterDropdownsSection from "../../../components/FilterDropdownsSection"
import SharedFilesTable from "./SharedFilesTable"

const dropdowns: DropdownProps[] = [
    {
        title: "gesendet von",
        options: [
            {name: "Person1", id: "23432"},
            {name: "person2", id: "54345"},
            {name: "person3", id: "485345"},
        ],
        icon: "project-diagram",
        type: "gesendetVon",
    },
    {
        title: "dateiformat",
        options: [
            {name: "dataiformat1", id: "254123"},
            {name: "dataiformat2", id: "857443"},
            {name: "dataiformat3", id: "34998"},
        ],
        icon: "calendar-alt",
        type: "dateiFormat",
    },
]

const SharedFiles = () => {
    const {t} = useTranslation("translations")

    const [sharedFilesFilterOptions, setSharedFilesFilterOptions] = React.useState({
        gesendetVon: "",
        dateiFormat: "",
    })

    const handleChange = (event: SelectChangeEvent) => {
        setSharedFilesFilterOptions({...sharedFilesFilterOptions, [event.target.name]: event.target.value})
    }

    return (
        <PageWrapper headerTitle={t("shared-files")} icon={["far", "share-alt-square"] as IconProp}>
            <FilterDropdownsSection handleChange={handleChange} filterValues={sharedFilesFilterOptions} dropdowns={dropdowns} />
            <SharedFilesTable />
        </PageWrapper>
    )
}

export default SharedFiles
