import React from "react"
import styles from "../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import {ResponsiveLine} from "@nivo/line"
import {formatDate} from "../../../../utils/dates"
import {GetReport_costs} from "../types/GetReport"
import {distinct} from "../../../../utils/arrays"

//DATA FOR GRAPH MUST BE IN THE FOLLOWING FORMAT:
// const data = [
//     {
//         id: "Planned",
//         data: [
//             {
//                 x: "Feb 2021",
//                 y: 100,
//             },
//
//         ],
//     },
//     {
//         id: "Actual",
//         data: [
//             {
//                 x: "Feb 2022",
//                 y: 19,
//             },
//         ],
//     },
// ]

interface Props {
    costs: GetReport_costs
}

interface GraphLineTypes {
    id: string
    data: {
        x: number
        y: number | null
    }[]
}

const CostsLineGraph: React.FC<Props> = ({costs}) => {
    const {t} = useTranslation("translations")

    const indexMap = new Map<number, string>()
    const planned = costs.planned.map((planned) => {
        indexMap.set(planned?.date_as_skalar!, formatDate(new Date(planned?.date!)))
        return {x: planned?.date_as_skalar!, y: planned?.amount ?? null}
    })
    const actual = costs.actual.map((actual) => {
        indexMap.set(actual?.date_as_skalar!, formatDate(new Date(actual?.date!)))
        return {x: actual?.date_as_skalar!, y: actual?.amount ?? null}
    })

    const graphData: GraphLineTypes[] = [
        {id: t("actual"), data: actual},
        {id: t("planned"), data: planned},
    ]

    return (
        <>
            {planned.length !== 0 || actual.length !== 0 ? (
                <div style={{height: "400px", marginBottom: "20px"}}>
                    <ResponsiveLine
                        data={graphData}
                        margin={{top: 50, right: 50, bottom: 50, left: 60}}
                        xScale={{
                            type: "linear",
                            min: 0,
                            max: [...actual, ...planned].map(entry => entry.x).filter(x => x % 1 === 0).filter(distinct).length + 1,
                        }}
                        xFormat={(value) => `${indexMap.get(Number(value))}`}
                        yScale={{
                            type: "linear",
                            min: 0,
                            max: "auto",
                            stacked: false,
                            reverse: false,
                        }}
                        yFormat=" >-.2f"
                        markers={
                            costs.report_date_as_skalar == null
                                ? []
                                : [
                                    {
                                        axis: "x",
                                        value: costs.report_date_as_skalar,
                                        legend: formatDate(new Date(costs.report_date)),
                                    },
                                ]
                        }
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 40,
                            // legend: "transportation",
                            legendOffset: 36,
                            legendPosition: "middle",
                            format: (value) => `${indexMap.get(value) || ""}`,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            // legend: "count",
                            legendOffset: -40,
                            legendPosition: "middle",
                        }}
                        pointSize={10}
                        pointColor={{theme: "background"}}
                        pointBorderWidth={2}
                        pointBorderColor={{from: "serieColor"}}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: "top",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: -30,
                                itemsSpacing: 0,
                                itemDirection: "left-to-right",
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 1,
                                symbolSize: 12,
                                symbolShape: "circle",
                                symbolBorderColor: "rgba(0, 0, 0, .5)",
                            },
                        ]}
                    />
                </div>
            ) : (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </>
    )
}

export default CostsLineGraph
