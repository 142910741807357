import React from "react"
//style imports
import styles from "./PageWrapper.module.scss"
//component imports
import PageHeader from "./PageHeader"
import {IconName, IconProp} from "@fortawesome/fontawesome-svg-core"

interface Props {
    headerTitle: string
    icon: IconName | IconProp
}

const PageWrapper: React.FC<Props> = ({children, headerTitle, icon}) => {
    return (
        <div className={styles.mainContentWrapper}>
            <PageHeader headerTitle={headerTitle} icon={icon} />
            {children}
        </div>
    )
}

export default PageWrapper
