import React from "react"
import {ResponsiveBar} from "@nivo/bar"
import useMediaQuery from "@mui/material/useMediaQuery"

const data = [
    {
        portfolio: "FY2-M03",
        "Planned Cost": 20,
        "Budget Cost": 26,
        "Actual Cost": 117,
    },
    {
        portfolio: "FY2-M04",
        "Planned Cost": 147,
        "Budget Cost": 86,
        "Actual Cost": 40,
    },
    {
        portfolio: "FY2-M05",
        "Planned Cost": 64,
        "Budget Cost": 101,
        "Actual Cost": 94,
    },
    {
        portfolio: "FY2-M06",
        "Planned Cost": 183,
        "Budget Cost": 53,
        "Actual Cost": 128,
    },
    {
        portfolio: "FY2-M07",
        "Planned Cost": 194,
        "Budget Cost": 109,
        "Actual Cost": 95,
    },
    {
        portfolio: "FY2-M08",
        "Planned Cost": 122,
        "Budget Cost": 100,
        "Actual Cost": 146,
    },
    {
        portfolio: "FY2-M09",
        "Planned Cost": 198,
        "Budget Cost": 34,
        "Actual Cost": 166,
    },
    {
        portfolio: "FY2-M10",
        "Planned Cost": 178,
        "Budget Cost": 34,
        "Actual Cost": 150,
    },
    {
        portfolio: "FY2-M11",
        "Planned Cost": 188,
        "Budget Cost": 52,
        "Actual Cost": 176,
    },
    {
        portfolio: "FY2-M12",
        "Planned Cost": 188,
        "Budget Cost": 52,
        "Actual Cost": 176,
    },
    {
        portfolio: "FY2-M13",
        "Planned Cost": 188,
        "Budget Cost": 52,
        "Actual Cost": 176,
    },
    {
        portfolio: "FY2-M14",
        "Planned Cost": 158,
        "Budget Cost": 32,
        "Actual Cost": 140,
    },
]

const PlanBudgetActualComparison = () => {
    const isXSScreen = useMediaQuery("(max-width: 600px)")

    return (
        <ResponsiveBar
            data={data}
            keys={["Planned Cost", "Budget Cost", "Actual Cost"]}
            indexBy="portfolio"
            margin={isXSScreen ? {top: 20, right: 20, bottom: 20, left: 20} : {top: 50, right: 130, bottom: 50, left: 60}}
            padding={0.3}
            groupMode="grouped"
            valueScale={{type: "linear"}}
            indexScale={{type: "band", round: true}}
            colors={{scheme: "nivo"}}
            defs={[
                {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#e39654",
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            fill={[
                {
                    match: {
                        id: "Planned Cost",
                    },
                    id: "dots",
                },
                {
                    match: {
                        id: "Actual Cost",
                    },
                    id: "lines",
                },
            ]}
            borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={
                isXSScreen
                    ? {
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: -50,
                          legendPosition: "middle",
                          legendOffset: 32,
                      }
                    : {
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendPosition: "middle",
                          legendOffset: 32,
                      }
            }
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: isXSScreen ? "bottom" : "bottom-right",
                    direction: isXSScreen ? "row" : "column",
                    justify: false,
                    translateX: isXSScreen ? 50 : 120,
                    translateY: isXSScreen ? 50 : -60,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                },
            ]}
            role="application"
            ariaLabel="Plan budget actual comparison chart"
        />
    )
}

export default PlanBudgetActualComparison
