import React from "react"
import PortfolioEvaluationPhasePlanned from "./Charts/PortfolioEvaluationPhasePlanned"
import PortfolioEvaluation from "./Charts/PortfolioEvaluation"
import styles from "./Overview.module.scss"
import {useTranslation} from "react-i18next"

const OverviewMainContent = () => {
    const {t} = useTranslation("translations")

    return (
        <div className={styles.mainContentWrapper}>
            <div className={styles.graphContainer}>
                <h5>{t("portfolio-eval-phase-planned-chart-title")}</h5>
                <div style={{height: "300px"}}>
                    <PortfolioEvaluationPhasePlanned />
                </div>
            </div>
            <div className={styles.graphContainer}>
                <h5>{t("portfolio-eval-chart-title")}</h5>
                <div style={{height: "300px"}}>
                    <PortfolioEvaluation />
                </div>
            </div>
        </div>
    )
}

export default OverviewMainContent
