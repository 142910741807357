import {gql} from "graphql-tag"

export const ReleasedOrInReviewFragment = gql`
    fragment ReleasedOrInReviewData on status_report_status_report {
        actual_end_date
        actual_start_date
        status_report_attachments {
            filename
            statusreport_id
        }
        description
        external_project_id
        external_program_id
        id
        next_milestones
        past_successes
        planned_start_date
        planned_end_date
        portfolio
        project_manager_user {
            id
            full_name
        }
        project_name
        project_phase
        project_status
        report_date
        state
        report_title
        state_histories {
            new_state
            timestamp
        }
        project_states {
            id
            state
            type
            status_report_id
            status_report {
                report_date
            }
        }
        milestones {
            actual_date
            description
            planned_date
            status
            id
            status_report_id
        }
        portfolio_project {
            deputy_project_lead
            sponsor
            deputy_sponsor
        }
        portfolio_program {
            deputy_program_lead
            deputy_sponsor
            sponsor
        }
    }
`
export const ReleasedOrInReviewFragmentVertraulich = gql`
    fragment ReleasedOrInReviewDataVertraulich on status_report_status_report {
        actual_costs
        status_report_attachments {
            filename
            statusreport_id
        }
        planned_costs
        project_costs(order_by: {date: asc}) {
            amount
            date
            id
            type
            status_report_id
        }
        project_states {
            comment
        }
        risks {
            damage_extent
            description
            assignee_user {
                id
                full_name
            }
            estimated_cost
            id
            next_assessment
            probability_of_occurrence
            status
            status_report_id
            countermeasures {
                assignee_user {
                    id
                    full_name
                }
                description
                due_date
                id
                status
            }
        }
        problems {
            assignee
            due_date
            id
            name
            status_report_id
            status
            assignee_user {
                id
                full_name
            }
        }
        portfolio_program {
            deputy_program_lead
            deputy_sponsor
            sponsor
        }
        change_requests {
            id
            description
            status
            status_report_id
            assignee_user {
                id
                full_name
            }
            reporter_user {
                id
                full_name
            }
        }
    }
`

export const UnreleasedOrRejectedFragment = gql`
    fragment UnreleasedOrRejectedData on status_report_status_report {
        status_report_attachments {
            filename
            statusreport_id
        }
        description
        external_project_id
        external_program_id
        id
        next_milestones
        past_successes
        state
        report_date
        report_title
        project_states {
            id
            state
            type
            status_report_id
            status_report {
                report_date
            }
            comment
        }
        portfolio_project {
            actual_end_date
            actual_start_date
            milestones(where: {reportable: {_eq: true}}) {
                actual_end_date
                issue_id
                planned_end_date
                status
                summary
            }
            planned_end_date
            planned_start_date
            portfolio {
                name
            }
            project_lead_user {
                id
                full_name
            }
            deputy_project_lead
            sponsor
            deputy_sponsor
            risks(where: {reportable: {_eq: true}}) {
                damage_impact
                estimated_costs
                issue_id
                probability
                status
                summary
                assignee_user {
                    id
                    full_name
                }
                next_assessment
                countermeasures {
                    assignee_user {
                        id
                        full_name
                    }
                    summary
                    due_date
                    issue_id
                    status
                }
            }
            problems(where: {reportable: {_eq: true}}) {
                issue_id
                status
                summary
                assignee_user {
                    id
                    full_name
                }
                due_date
            }
            change_requests(where: {reportable: {_eq: true}}) {
                issue_id
                summary
                status
                assignee_user {
                    id
                    full_name
                }
                reporter_user {
                    id
                    full_name
                }
            }
            status
            phase
            summary
        }
        portfolio_program {
            actual_end_date
            actual_start_date
            milestones(where: {reportable: {_eq: true}}) {
                actual_end_date
                issue_id
                planned_end_date
                status
                summary
            }
            planned_end_date
            planned_start_date
            portfolio {
                name
            }
            projects {
                summary
                milestones {
                    actual_end_date
                    status
                    planned_end_date
                    issue_id
                    summary
                }
                risks {
                    damage_impact
                    estimated_costs
                    issue_id
                    probability
                    summary
                    status
                    assignee_user {
                        id
                        full_name
                    }
                    next_assessment
                    countermeasures {
                        assignee_user {
                            id
                            full_name
                        }
                        summary
                        status
                        issue_id
                        due_date
                    }
                }
                problems(where: {reportable: {_eq: true}}) {
                    issue_id
                    status
                    summary
                    assignee_user {
                        id
                        full_name
                    }
                    due_date
                }
                change_requests(where: {reportable: {_eq: true}}) {
                    issue_id
                    summary
                    status
                    assignee_user {
                        id
                        full_name
                    }
                    reporter_user {
                        id
                        full_name
                    }
                }
            }
            program_lead_user {
                id
                full_name
            }
            deputy_program_lead
            sponsor
            deputy_sponsor

            risks(where: {reportable: {_eq: true}}) {
                damage_impact
                estimated_costs
                issue_id
                probability
                status
                summary
                assignee_user {
                    id
                    full_name
                }
                next_assessment
                countermeasures {
                    assignee_user {
                        id
                        full_name
                    }
                    summary
                    due_date
                    issue_id
                    status
                }
            }
            problems(where: {reportable: {_eq: true}}) {
                issue_id
                status
                summary
                assignee_user {
                    id
                    full_name
                }
                due_date
            }
            change_requests(where: {reportable: {_eq: true}}) {
                issue_id
                summary
                status
                assignee_user {
                    id
                    full_name
                }
                reporter_user {
                    id
                    full_name
                }
            }
            status
            phase
            summary
        }
    }
`
