import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"
import PageWrapper from "../../../components/PageWrapper"
import FilterDropdownsSection, {DropdownProps} from "../../../components/FilterDropdownsSection"
import {gql, useQuery} from "@apollo/client"
import {FilterPortfolios, FilterPortfoliosVariables} from "./types/FilterPortfolios"
import PortfolioReportMainContent from "./PortfolioReportMainContent"
import CircularProgress from "@mui/material/CircularProgress"
import {useReactToPrint} from "react-to-print"
import {getPageMargins} from "../../../utils/printStyles"
import ReportPrintVersion from "./ReportPrintVersion"
import {PortfolioReportData} from "./portfolioReportData"
import {capitalizeFirstLetter} from "../../../utils/strings"
import {GetPortfolioReportFilterOptions} from "./types/GetPortfolioReportFilterOptions"
import {distinct, isPresent} from "../../../utils/arrays"

const getPortfolioReportFilterOptions = gql`
    query GetPortfolioReportFilterOptions {
        portfolio: jira_rm_portfolio {
            name
            project_org_units: projects(distinct_on: organisation_unit, where: {organisation_unit: {_is_null: false}}) {
                organisation_unit
            }
            project_theme_tags: projects(distinct_on: theme_tags, where: {theme_tags: {_is_null: false}}) {
                theme_tags
            }
            program_org_units: programs(distinct_on: organisation_unit, where: {organisation_unit: {_is_null: false}}) {
                organisation_unit
            }
            program_org_theme_tags: programs(distinct_on: theme_tags, where: {theme_tags: {_is_null: false}}) {
                theme_tags
            }
        }
    }
`

const filterPortfolios = gql`
    query FilterPortfolios($whereProject: jira_rm_portfolio_project_bool_exp!, $whereProgram: jira_rm_portfolio_program_bool_exp!) {
        projects: jira_rm_portfolio_project(where: $whereProject) {
            actual_start_date
            actual_end_date
            planned_start_date
            planned_end_date
            issue_id
            score_risk
            score_size
            score_value
            type
            portfolio {
                name
            }
            theme_tags
            phase
            kind
            priority
            execution_type
            organisation_unit
            category
            summary
            status
            cross_section_selections
            overview_url
            cost_control {
                overview {
                    controlling_plan_id
                    name
                    type
                    period
                    expense_type
                    budget
                    planned
                    actual
                }
            }
            status_reports(where: {state: {_eq: "released"}}) {
                id
                report_date
                project_states {
                    type
                    state
                    id
                }
            }
        }
        programs: jira_rm_portfolio_program(where: $whereProgram) {
            actual_start_date
            actual_end_date
            planned_start_date
            planned_end_date
            issue_id
            score_risk
            score_size
            score_value
            type
            portfolio {
                name
            }
            theme_tags
            phase
            kind
            priority
            execution_type
            organisation_unit
            category
            summary
            status
            cross_section_selections
            overview_url
            cost_control {
                overview {
                    controlling_plan_id
                    name
                    type
                    period
                    expense_type
                    budget
                    planned
                    actual
                }
            }
            status_reports(where: {state: {_eq: "released"}}) {
                id
                report_date
                project_states {
                    type
                    state
                    id
                }
            }
            portfolio {
                name
            }
            organisation_unit
            kind
            category
            score_risk
            score_size
            score_value
            type
        }
    }
`

const PortflioReport = () => {
    const {t} = useTranslation("translations")

    const printStatusReportRef = React.useRef<HTMLDivElement | null>(null)

    const [printing, setPrinting] = React.useState<boolean>(false)

    const [portfolioOptions, setPortfolioOptions] = useState<{name: string; id: string}[]>([])
    const [orgUnitOptions, setOrgUnitOptions] = useState<{name: string; id: string}[]>([])

    const [overviewFilterOptions, setOverviewFilterOptions] = React.useState({
        portfolio: "",
        status: "",
        organisation_unit: "",
        kind: "",
        category: "",
        type: "",
        cross_section_selection: "",
        execution_type: "",
        theme_tags: "",
    })
    let filterVariables = {}
    Object.entries(overviewFilterOptions)
        .filter((x) => x[1] !== "")
        .forEach((x) => {
            if (x[0] === "portfolio") {
                filterVariables = {
                    ...filterVariables,
                    portfolio: {name: {_eq: x[1]}},
                }
            } else  if (x[0] === "cross_section_selection") {
                filterVariables = {
                    ...filterVariables,
                    cross_section_selections: {_contains: x[1]},
                }
            } else if (x[0] === "theme_tags") {
                filterVariables = {
                    ...filterVariables,
                    theme_tags: {_contains: x[1]},
                }
            } else {
                filterVariables = {
                    ...filterVariables,
                    [x[0]]: {_eq: x[1]},
                }
            }
        })

    const {data, error} = useQuery<GetPortfolioReportFilterOptions>(getPortfolioReportFilterOptions)
    const {
        data: filteredData,
        loading: filteredLoading,
        error: filteredDataError,
    } = useQuery<FilterPortfolios, FilterPortfoliosVariables>(filterPortfolios, {
        variables: {
            whereProject: filterVariables,
            whereProgram: filterVariables,
        },
    })
    if (error || filteredDataError) throw Error(error?.message || filteredDataError?.message)

    const createOptions = useCallback(
        (array: string[], uppercase?: boolean) => [
            {name: `${t("all")}`, id: ""},
            ...array.map((x) => {
                if (uppercase) {
                    return {name: capitalizeFirstLetter(x), id: x}
                } else {
                    return {name: x, id: x}
                }
            }),
        ],
        [t]
    )

    const portfolios =
        data?.portfolio
            .map((portfolio) => portfolio.name)
            .filter(isPresent)
            .filter(distinct) ?? []

    const themeTags: string[] =
        data?.portfolio
            .flatMap((p) => {
                const programs: string[] = p?.program_org_theme_tags.flatMap((program) => program.theme_tags).filter(isPresent) || []
                const projects: string[] = p?.project_theme_tags.flatMap((project) => project.theme_tags).filter(isPresent) || []
                return [...programs, ...projects]
            })
            .filter(distinct) ?? []

    const getAllOrgUnits = useCallback(() => {
        const result =
            data?.portfolio
                .map((p) => {
                    const programs = p?.program_org_units.map((program) => program.organisation_unit).filter(isPresent) || []
                    const projects = p?.project_org_units.map((project) => project.organisation_unit).filter(isPresent) || []
                    return [...programs, ...projects]
                })
                .filter(isPresent)
                .filter(distinct) ?? []

        return Array.from(new Set(result.flat()))
    }, [data])

    useEffect(() => {
        if (!filteredData) return

        if (!overviewFilterOptions.portfolio) {
            setOrgUnitOptions(createOptions(getAllOrgUnits()))
        } else {
            const programOrgUnits = filteredData?.programs.map((program) => program.organisation_unit).filter(isPresent)
            const projectsOrgUnits = filteredData?.projects.map((project) => project.organisation_unit).filter(isPresent)
            const filteredOrgUnits = [...programOrgUnits, ...projectsOrgUnits].filter((org) => org).filter(distinct)
            setOrgUnitOptions(createOptions(filteredOrgUnits))
        }

        if (!overviewFilterOptions.organisation_unit) {
            setPortfolioOptions(createOptions(portfolios))
        } else {
            const programPortfolios = filteredData?.programs.map((program) => program.portfolio?.name).filter(isPresent)
            const projectPortfolios = filteredData?.projects.map((project) => project.portfolio?.name).filter(isPresent)
            const filteredPortfolios = [...programPortfolios, ...projectPortfolios].filter((port) => port).filter(distinct)
            setPortfolioOptions(createOptions(filteredPortfolios))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, getAllOrgUnits, createOptions, overviewFilterOptions.portfolio, overviewFilterOptions.organisation_unit])

    const reportData = new PortfolioReportData(filteredData)

    const dropdowns: DropdownProps[] = [
        {
            title: "portfolio",
            options: portfolioOptions,
            icon: "building",
            type: "portfolio",
        },
        {
            title: "status",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("status-in-bearbeitung"), id: "In Bearbeitung"},
                {name: t("status-identifiziert"), id: "Identifiziert"},
                {name: t("status-vorbereitung"), id: "Vorbereitung"},
                {name: t("status-sistiert"), id: "Sistiert"},
                {name: t("status-storniert"), id: "Storniert"},
                {name: t("status-abgebrochen"), id: "Abgebrochen"},
                {name: t("status-abgeschlossen"), id: "Abgeschlossen"},
            ],
            icon: "tasks",
            type: "status",
        },
        {
            title: "organization_unit",
            options: orgUnitOptions,
            icon: "book",
            type: "organisation_unit",
        },
        {
            title: "category",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("category-digitalisierung"), id: "Digitalisierung"},
                {name: t("category-digitalisierung-schwerpunkt"), id: "Digitalisierung Schwerpunkt"},
            ],
            icon: "th",
            type: "category",
        },
        {
            title: "kind",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("kind-non-ict"), id: "Non-ICT"},
                {name: t("kind-ict"), id: "ICT"},
            ],
            icon: "th-large",
            type: "kind",
        },
        {
            title: "type",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("type-life-cycle"), id: "Life Cycle"},
                {name: t("type-innovation"), id: "Innovation"},
                {name: t("type-governance"), id: "Governance"},
            ],
            icon: "book",
            type: "type",
        },
        {
            title: "cross_section_selection",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("cross-section-allgemeine-verwaltung"), id: "Allgemeine Verwaltung"},
                {
                    name: t("cross-section-öffentliche-ordnung-bevölkerung-sicherheit-verteidigung"),
                    id: "Öffentliche Ordnung, Bevölkerung, Sicherheit, Verteidigung",
                },
                {name: t("cross-section-bildung"), id: "Bildung"},
                {name: t("cross-section-kultur-sport-und-freizeit-kirche"), id: "Kultur, Sport und Freizeit, Kirche"},
                {name: t("cross-section-gesundheit"), id: "Gesundheit"},
                {name: t("cross-section-soziale-sicherheit"), id: "Soziale Sicherheit"},
                {name: t("cross-section-verkehr-und-nachrichtenübermittlung"), id: "Verkehr und Nachrichtenübermittlung"},
                {name: t("cross-section-umweltschutz-und-raumordnung"), id: "Umweltschutz und Raumordnung"},
                {name: t("cross-section-volkswirtschaft"), id: "Volkswirtschaft"},
                {name: t("cross-section-finanzen-und-steuern"), id: "Finanzen und Steuern"},
                {name: t("cross-section-bau--und-wohnungswesen"), id: "Bau- und Wohnungswesen"},
                {name: t("cross-section-geschäftspartner"), id: "Geschäftspartner"},
                {name: t("cross-section-geographie"), id: "Geographie"},
                {name: t("cross-section-statistik"), id: "Statistik"},
                {name: t("cross-section-kdi-relevanz"), id: "KDI-Relevanz"},
            ],
            icon: "th",
            type: "cross_section_selection",
        },
        {
            title: "execution_type",
            options: [
                {name: `${t("all")}`, id: ""},
                {name: t("execution-type-agile"), id: "Agil"},
                {name: t("execution-type-hybrid"), id: "Hybrid"},
                {name: t("execution-type-classic"), id: "Klassisch"},
            ],
            icon: "arrows-split-up-and-left",
            type: "execution_type",
        },
        {
            title: "theme_tags",
            options: createOptions(themeTags),
            icon: "tags",
            type: "theme_tags",
        },
    ]

    const handleChange = (event: SelectChangeEvent) => {
        setOverviewFilterOptions({...overviewFilterOptions, [event.target.name]: event.target.value})
    }

    const handlePrint = useReactToPrint({
        content: () => printStatusReportRef.current,
        pageStyle: `@media print {  .printDisplay {display: block !important;}}`,
    })

    const handlePrintingReport = () => {
        setPrinting(true)
        //NOTE: The Nivo charts would not load in time when simply using "display: none" as suggested by react-to-print to print components that are only viewable during print and not at all on @media: screen. Therefore, this timeout to toggle the printing state will switch the view of the page to the "print friendly" component for a moment to allow for the DOM to load the charts. Without this the page will have all charts show as empty in the print-preview.
        setTimeout(() => {
            handlePrint()
            setPrinting(false)
        }, 200)
    }

    if (filteredLoading)
        return (
            <div>
                <p>{t("loading")}</p>
                <CircularProgress />
            </div>
        )

    return (
        <>
            <style>{getPageMargins()}</style>
            <div ref={printStatusReportRef}>
                <PageWrapper headerTitle={t("portfolio-report")} icon={"list" as IconProp}>
                    <FilterDropdownsSection
                        handleChange={handleChange}
                        handlePrint={handlePrintingReport}
                        filterValues={overviewFilterOptions}
                        dropdowns={dropdowns}
                    />
                    {/* //NOTE: ReportPrintVersion is the PortfolioReport redone entirely without Mui components. The combination of
                    react-to-print, SVG Nivo Charts & Mui Grid system (which is needed for the graphs on @media: screen view) was causing
                    the graphs to completely jump and leave their container divs in print-preview. By creating a conditional print-friendly
                    component for the PortfolioReport that only shows up as the document is being printed was the the only way to solve this
                    issue. */}
                    <>{printing ? <ReportPrintVersion data={reportData} /> : <PortfolioReportMainContent data={reportData} />}</>
                </PageWrapper>
            </div>
        </>
    )
}

export default PortflioReport
