import {ResponsiveScatterPlot} from "@nivo/scatterplot"
import {useTranslation} from "react-i18next"
import {PortfolioReportData} from "../portfolioReportData"
import styles from "../PortfolioReport.module.scss"

interface Props {
    data: PortfolioReportData
    printVersion?: boolean
}

const PortfolioEvaluationPhasePlanned: React.FC<Props> = ({data, printVersion}) => {
    const {t} = useTranslation("translations")

    const chartData = data.projectsAndPrograms().map((project) => {
        return {
            id: project.summary,
            data: [
                {
                    x: project.score_risk ?? 0,
                    y: project.score_value ?? 0,
                    z: project.score_size ?? 0,
                    url: project.overview_url,
                },
            ],
        }
    })

    return (
        <ResponsiveScatterPlot
            animate={!printVersion}
            data={chartData || []}
            margin={{
                top: 30,
                // right: 300,
                right: 60,
                bottom: 45,
                left: 60,
            }}
            xScale={{type: "linear", min: 0, max: "auto"}}
            xFormat=" >-.2f"
            yScale={{type: "linear", min: 0, max: "auto"}}
            yFormat=">-.2f"
            blendMode="multiply"
            nodeSize={{key: "x", values: [6, 500], sizes: [9, 32]}}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: <tspan style={{fontWeight: 550}}>{t("portfolio-report-score-risk")}</tspan>,
                legendOffset: 40,
                legendPosition: "middle",
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: <tspan style={{fontWeight: 550}}>{t("portfolio-report-score-value")}</tspan>,
                legendOffset: -50,
                legendPosition: "middle",
            }}
            // legends={[
            //     {
            //         anchor: "bottom-right",
            //         direction: "column",
            //         justify: false,
            //         translateX: 150,
            //         translateY: -40,
            //         itemWidth: 100,
            //         itemHeight: 12,
            //         itemsSpacing: 5,
            //         itemDirection: "left-to-right",
            //         symbolSize: 12,
            //         symbolShape: "circle",
            //         effects: [
            //             {
            //                 on: "hover",
            //                 style: {
            //                     itemOpacity: 1,
            //                 },
            //             },
            //         ],
            //     },
            // ]}
            tooltip={({node}) => {
                return (
                    <div className={styles.graphToolTip} style={{flexDirection: "column"}}>
                        <div className={styles.projectNameWrapper}>
                            <div className={styles.colorBox} style={{backgroundColor: `${node.color}`}}></div>
                            <p>{node.id}</p>
                        </div>
                        <div className={styles.valuesWrapper}>
                            <div className={styles.value}>
                                <p style={{textAlign: "left"}}>{`${t("portfolio-report-score-risk")}: `}</p>
                                <span style={{fontWeight: "bolder", textAlign: "right"}}>{node.formattedX}</span>
                            </div>
                            <div className={styles.value}>
                                <p style={{textAlign: "left"}}>{`${t("portfolio-report-score-value")}: `}</p>
                                <p style={{fontWeight: "bolder"}}>{node.formattedY}</p>
                            </div>
                        </div>
                    </div>
                )
            }}
            onClick={(bubble) => {
                if (bubble.data.url) {
                    window.open(bubble.data.url, "_blank")
                }
            }}
        />
    )
}

export default PortfolioEvaluationPhasePlanned
