import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, withStyles, makeStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {subDays} from "date-fns"
import {StatusReportMilestone} from "../statusReportData"
import {formatDate} from "../../../../utils/dates"
import {formatStringForTranslation} from "../../../../utils/strings"

interface Props {
    milestones: StatusReportMilestone[]
}

const useStyles = makeStyles(() =>
    createStyles({
        descTableCell: {
            width: "250px",
        },
        statusTableCell: {
            width: "80px",
        },
        endTableCell: {
            width: "180px",
        },
    })
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#fff",
            },
        },
    })
)(TableRow)

const Milestones: React.FC<Props> = ({milestones}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    const statusIconPicker = (date: Date | string | null) => {
        if (!date) {
            // TODO questionmark?
            return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337"}} />
        }
        const todaysDate = new Date()
        const fiveDaysBeforeDeadline = subDays(new Date(date), 5)
        const dateDeadline = new Date(date)
        if (todaysDate >= fiveDaysBeforeDeadline && todaysDate < dateDeadline)
            return <FontAwesomeIcon icon="minus-circle" style={{color: "#dfd139"}} />
        if (dateDeadline > todaysDate) return <FontAwesomeIcon icon="check-circle" style={{color: "#48c792"}} />
        if (dateDeadline < todaysDate) return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337"}} />
    }

    return (
        <div className={styles.tableContainer}>
            {milestones.length !== 0 && (
                <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                    <Table size="small" aria-label="milestones table" style={{tableLayout: "fixed"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.descTableCell}>
                                    {t("short-description")}
                                </TableCell>
                                <TableCell align="left" className={classes.statusTableCell}>
                                    {t("status")}
                                </TableCell>
                                <TableCell align="right">{t("planned-date")}</TableCell>
                                <TableCell align="right">{t("actual-date")}</TableCell>
                                <TableCell align="right" className={classes.endTableCell}>
                                    {t("variance")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {milestones.map((milestone, index) => (
                                <StyledTableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell align="left" className={classes.descTableCell}>
                                        <span style={{marginRight: "10px"}}>{statusIconPicker(milestone.planned_date)}</span>
                                        {milestone.description}
                                    </TableCell>
                                    <TableCell align="left" className={classes.statusTableCell}>
                                        {t(`milestone-status-${formatStringForTranslation(milestone.status)}`)}
                                    </TableCell>
                                    <TableCell align="right">{formatDate(milestone.planned_date)}</TableCell>
                                    <TableCell align="right">{formatDate(milestone.actual_date)}</TableCell>
                                    <TableCell align="right" className={classes.endTableCell}>
                                        {milestone.variance()} {milestone.variance() ? t("days") : ""}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {milestones.length === 0 && (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </div>
    )
}

export default Milestones
