export const formatAmount = (x: number | null | undefined) => {
    if (x === null || x === undefined) {
        return "-"
    }
    return formatToFixed(x, 0) + ".-"
}

export const formatToFixed = (x: number, fractionDigits: number) => {
    return formatNumber(x.toFixed(fractionDigits))
}

export const formatNumber = (value: string | number | null): string => {
    if (!value) {
        return "0"
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
}
