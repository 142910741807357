export const getPageMargins = () => {
    return `@page { 
            margin: 10px 50px !important; 
        }`
}

export const getPageMarginsDataGrid = () => {
    return `@page { 
            margin: 10px 20px !important; 
        }`
}
