import React from "react"
import {useTranslation} from "react-i18next"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"
import PageWrapper from "../../../components/PageWrapper"
import FilterDropdownsSection from "../../../components/FilterDropdownsSection"
import {DropdownProps} from "../../../components/FilterDropdownsSection"
import PBACMainContent from "./PBACMainContent"

const PlanBudgetActualComparison = () => {
    const {t} = useTranslation("translations")

    const [pbacFilterOptions, setPBACFilterOptions] = React.useState({
        portfolio: "",
        businessDivision: "",
        type: "",
    })

    const handleChange = (event: SelectChangeEvent) => {
        setPBACFilterOptions({...pbacFilterOptions, [event.target.name]: event.target.value})
    }

    const dropdowns: DropdownProps[] = [
        {
            title: "portfolio",
            options: [
                {name: "Portfolio1", id: "23904"},
                {name: "Portfolio2", id: "98234"},
                {name: "Portfolio3", id: "54345"},
            ],
            icon: "building",
            type: "portfolio",
        },

        {
            title: "type",
            options: [
                {name: "Type1", id: "7665"},
                {name: "Type2", id: "349858"},
                {name: "Type3", id: "45343"},
            ],
            icon: "book",
            type: "type",
        },
        // {
        //     title: "business division",
        //     options: ["BusinessDiv1", "BusinessDiv2", "BusinessDiv3"],
        //     icon: "briefcase",
        //     type: "businessDivision",
        // },
        // {title: "type", options: ["Type1", "Type2", "Type3"], icon: "th-large", type: "type"},
    ]

    return (
        <PageWrapper headerTitle={t("plan-budget-actual-comparison")} icon={"chart-bar" as IconProp}>
            <FilterDropdownsSection handleChange={handleChange} filterValues={pbacFilterOptions} dropdowns={dropdowns} />
            <PBACMainContent />
        </PageWrapper>
    )
}

export default PlanBudgetActualComparison
