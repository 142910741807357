import React from "react"

interface Props {
    dataWithArc: any
    centerX: any
    centerY: any
}

export const CenteredMetric: React.FC<Props> = ({dataWithArc, centerX, centerY}) => {
    let total = 0
    dataWithArc.forEach((data: any) => {
        total += data.value
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: "30px",
                fontWeight: 500,
            }}
        >
            {total}
        </text>
    )
}
