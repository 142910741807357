import React from "react"
import {useTranslation} from "react-i18next"
import {SelectChangeEvent} from "@mui/material/Select"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import PageWrapper from "../../../components/PageWrapper"
import FilterDropdownsSection from "../../../components/FilterDropdownsSection"
import {DropdownProps} from "../../../components/FilterDropdownsSection"
import PSDMainContent from "./PSDMainContent"

const dropdowns: DropdownProps[] = [
    {
        title: "portfolio",
        options: [
            {name: "Portfolio1", id: "23904"},
            {name: "Portfolio2", id: "98234"},
            {name: "Portfolio3", id: "54345"},
        ],
        icon: "building",
        type: "portfolio",
    },

    {
        title: "type",
        options: [
            {name: "Type1", id: "7665"},
            {name: "Type2", id: "349858"},
            {name: "Type3", id: "45343"},
        ],
        icon: "book",
        type: "type",
    },
]

const ProjectStatusDevelopment = () => {
    const {t} = useTranslation("translations")
    const [psDevFilterOptions, setPSDevFilterOptions] = React.useState({
        portfolio: "",
        businessDivision: "",
        type: "",
    })

    const handleChange = (event: SelectChangeEvent) => {
        setPSDevFilterOptions({...psDevFilterOptions, [event.target.name]: event.target.value})
    }

    return (
        <PageWrapper headerTitle={t("project-status-development")} icon={["far", "file-chart-line"] as IconProp}>
            <FilterDropdownsSection handleChange={handleChange} filterValues={psDevFilterOptions} dropdowns={dropdowns} />
            <PSDMainContent />
        </PageWrapper>
    )
}

export default ProjectStatusDevelopment
