import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, withStyles, makeStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {StatusReportProblem} from "../statusReportData"
import {format} from "date-fns"
import {formatStringForTranslation} from "../../../../utils/strings"

interface Props {
    problems: StatusReportProblem[]
}

const useStyles = makeStyles(() =>
    createStyles({
        descTableCell: {
            width: "250px",
        },
        assigneeCell: {
            width: "180px",
        },
        statusTableCell: {
            width: "80px",
        },
    })
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#fff",
            },
        },
    })
)(TableRow)

const Problems: React.FC<Props> = ({problems}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    return (
        <>
            {problems.length !== 0 && (
                <div className={styles.tableContainer}>
                    <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                        <Table size="small" aria-label="simple table" style={{tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.descTableCell}>
                                        {t("name")}
                                    </TableCell>
                                    <TableCell align="left" className={classes.statusTableCell}>
                                        {t("status")}
                                    </TableCell>
                                    <TableCell align="right">{t("due_date")}</TableCell>
                                    <TableCell align="right" className={classes.assigneeCell}>
                                        {t("assignee")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {problems.map((row, index) => {
                                    return (
                                        <StyledTableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                            <TableCell align="left" className={classes.descTableCell}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left" className={classes.statusTableCell}>
                                                {t(`problem-status-${formatStringForTranslation(row.status)}`)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.due_date && format(new Date(row.due_date), "dd.MM.yyyy")}
                                            </TableCell>
                                            <TableCell align="right" className={classes.assigneeCell}>
                                                {row.assignee?.full_name ?? ""}
                                            </TableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}

            {problems.length === 0 && (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </>
    )
}

export default Problems
