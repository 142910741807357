import React from "react"
import {ResponsivePie} from "@nivo/pie"
import useMediaQuery from "@mui/material/useMediaQuery"
import {colorSchemes} from "@nivo/colors"
import {useTranslation} from "react-i18next"
import styles from "../PortfolioReport.module.scss"
import {PortfolioReportData} from "../portfolioReportData"
import {GraphDialogProps} from "../PortfolioReportMainContent"
import {formatStringForTranslation} from "../../../../utils/strings"
import {CenteredMetric} from "./CenteredMetricForPieCharts"

interface Props {
    data: PortfolioReportData
    setTableDialogOpen?: (value: GraphDialogProps) => void
    printVersion?: boolean
}

const ByCategoryPieChart: React.FC<Props> = ({data, setTableDialogOpen, printVersion}) => {
    const {t} = useTranslation("translations")
    const isXSScreen = useMediaQuery("(max-width: 600px)")

    const mobileSize = {top: 20, right: 80, bottom: 80, left: 80}

    const determineMargins = () => {
        if (isXSScreen) return {top: 20, right: 20, bottom: 20, left: 20}
        if (printVersion) return {top: -40, right: 100, bottom: 30, left: 20}
        else return mobileSize
    }

    const chartData = [
        {
            id: "Digitalisierung Schwerpunkt",
            label: `${t("category-digitalisierung-schwerpunkt")}`,
            value: 0,
            color: "hsl(234, 70%, 50%)",
        },
        {
            id: "Digitalisierung",
            label: `${t("category-digitalisierung")}`,
            value: 0,
            color: "hsl(167, 70%, 50%)",
        },
        {
            id: "Keine",
            label: `${t("category-keine")}`,
            value: 0,
            color: "hsl(169, 70%, 50%)",
        },
    ]

    const generateChartData = () => {
        data.projectsAndPrograms().forEach((project) => {
            if (project.category === "" || project.category === null) {
                const keine = chartData.find((bar) => {
                    return bar.id === "Keine"
                })
                keine!.value += 1
            }
            chartData.forEach((bar) => {
                if (bar.id === project.category) {
                    bar.value += 1
                }
            })
        })
        return chartData
    }

    return (
        <div className={styles.statusPieWrapper}>
            <ResponsivePie
                layers={["arcs", "arcLabels", "legends", CenteredMetric]}
                animate={!printVersion}
                data={generateChartData()}
                margin={determineMargins()}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={1}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                }}
                tooltip={(item) => {
                    return (
                        <div className={styles.graphToolTip}>
                            <div className={styles.colorBox} style={{backgroundColor: `${item.datum.color}`}}></div>
                            <p>
                                {item.datum.label} {` - `}
                                <b>{item.datum.value}</b>
                            </p>
                        </div>
                    )
                }}
                theme={{
                    fontSize: 12,
                    labels: {
                        text: {
                            fontWeight: 800,
                            fontSize: 14,
                        },
                    },
                }}
                onClick={(node) => {
                    setTableDialogOpen && setTableDialogOpen({graphType: "category", filterValue: node.data.id})
                }}
            />
            <div className={styles.legend}>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: colorSchemes.nivo[0]}}></div>
                    <span>{t(`${chartData[0].label}`)}</span>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: colorSchemes.nivo[1]}}></div>
                    <span>{t(`${chartData[1].label}`)}</span>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: colorSchemes.nivo[2]}}></div>
                    <span>{t(`${chartData[2].label}`)}</span>
                </div>
            </div>
        </div>
    )
}

export default ByCategoryPieChart
